
import { CampaignListItem, CampaignReport, CampaignStatus } from "../../types";
import {
  Box,
  IconButton,
  Menu,
  MenuItem, Skeleton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { CAMPAIGN_STATUS_INDICATIONS } from "../../utils";
import { formatDate, goToRouteLinkWithId, MoreVertIcon, numberToStringWithCommas } from "../../../global";
import { style } from "./style";
import { useTranslation } from "react-i18next";
import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { NotificationService } from "../../../global/services";
import { CampaignService } from "../../services";
import { StatusCode } from "../../../global/types";
import warningIcon from "../../../../assets/images/sidebar_warning.png";
import { appPaths } from "../../../../config/routes/paths";

type Props = {
  campaign: CampaignListItem;
  updateCampaignList:() => void;
  currency?: string;
  isLoading?: boolean;
  loadingHandler:(state: boolean) => void;
  noDataToDisplay?: boolean;
  report?: CampaignReport | any;
  archiveCampaign: (campaignId: string) => void;
  replicateCampaign?: (campaignId: string) => void;
  setOpenReplicateModal: (campaignId: string) => void;
}

const parseCtr = (ctr: number) => ctr.toFixed(2);
const renderStatusItem = (status_key: CampaignStatus) => {
  return !(status_key === CampaignStatus.DRAFT ||
    status_key === CampaignStatus.IN_MODERATION ||
    status_key === CampaignStatus.REJECTED ||
    status_key === CampaignStatus.FINISHED_OVERDUE ||
    status_key === CampaignStatus.FINISHED_BUDGET_SPENT)
}

const hasConversion = (type: string): boolean => (
  type === 'APP INSTALLS' || type === 'WEB STANDARD CONVERSION' ||
  type === 'WEBSITE CUSTOM EVENT' || type === 'APP CUSTOM EVENT');

function CampaignListRow({ report, campaign: {
  status_key,
  name,
  budget,
  campaignType,
  creative_status,
  campaign_start_at,
  id,
}, isLoading, noDataToDisplay, currency, archiveCampaign,setOpenReplicateModal, replicateCampaign, updateCampaignList, loadingHandler }: Props) {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.list' });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  function handleMenuOpen(event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  async function onStatusChange() {
    setAnchorEl(null)
    if (status_key === CampaignStatus.DRAFT) {
      NotificationService.handleError(t('rejectReasons.draft'))
    } else if (status_key === CampaignStatus.IN_MODERATION) {
      NotificationService.handleError(t('rejectReasons.inModeration'))
    } else if (status_key === CampaignStatus.REJECTED) {
      NotificationService.handleError(t('rejectReasons.rejected'))
    } else if (status_key === CampaignStatus.FINISHED_OVERDUE) {
      NotificationService.handleError(t('rejectReasons.finished'))
    } else if (status_key === CampaignStatus.FINISHED_BUDGET_SPENT) {
      NotificationService.handleError(t('rejectReasons.budgetSpent'))
    } else if (
      status_key === CampaignStatus.PAUSED
      || status_key === CampaignStatus.PAUSED_DUE_TO_USER_BALANCE_SPENT
      || status_key === CampaignStatus.WAITING_TO_START || status_key === CampaignStatus.ARCHIVED
    ) {
      loadingHandler(true);
      const response = await CampaignService.runCampaign(id);
      if (response?.data?.type?.code === StatusCode.OK) {
        updateCampaignList();
      } else {
        loadingHandler(false);
        NotificationService.handleError(response?.data?.type?.message);
      }
    } else if (status_key === CampaignStatus.RUNNING || status_key === CampaignStatus.RUNNING_DAILY_BUDGET_REACHED) {
      loadingHandler(true);
      const response = await CampaignService.pauseCampaign(id);
      if (response?.data?.type?.code === StatusCode.OK) {
        updateCampaignList();
      } else {
        loadingHandler(false);
        NotificationService.handleError(response?.data?.type?.message);
      }
    }
  }

  function onReplicate(e: MouseEvent) {
    e.stopPropagation();
    setAnchorEl(null);
    setOpenReplicateModal(id)
    // replicateCampaign(id);
  }

  return (
    <TableRow
      onClick={() => id.length > 5 ? history.push(goToRouteLinkWithId(appPaths.editCampaign, id)) : undefined}
      sx={[style.tableRow, {'&:hover': {
          background: isLoading ? '#FFFFFF' : '#f2f8fc',
        }, visibility: noDataToDisplay ? 'hidden' : 'inherit'}]}
    >
      <TableCell sx={{borderBottom: noDataToDisplay ? 'none' : '1px solid rgba(224, 224, 224, 1)'}}>
        <Box sx={style.campaignNameCell}>
          {isLoading ? (<Box sx={style.skeletonWrap}>
            <Skeleton sx={style.multiRowSkeleton} variant="rectangular" />
          </Box>) : null }
          {creative_status === "disapproved" ? <Box><img src={warningIcon as string} alt="warning" /></Box> :
          <Box sx={style.round(CAMPAIGN_STATUS_INDICATIONS[status_key || CampaignStatus.DRAFT])} />}
          <Box>
            <Tooltip title={name}>
              <Typography sx={style.campaignName}>{name}</Typography>
            </Tooltip>
            <Typography sx={style.campaignStatus(CAMPAIGN_STATUS_INDICATIONS[status_key || CampaignStatus.DRAFT])}>
              {t(`status.${status_key}`)}
              {
                (status_key === CampaignStatus.RUNNING && campaign_start_at) ? formatDate(campaign_start_at) :
                  status_key === CampaignStatus.WAITING_TO_START && new Date(campaign_start_at as string) > new Date() ? formatDate(campaign_start_at) : ''
              }
            </Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={{borderBottom: noDataToDisplay ? 'none' : '1px solid rgba(224, 224, 224, 1)'}}>
        <Box sx={style.rowCollection}>
          {isLoading ? (<Box sx={style.skeletonWrap}>
            <Skeleton sx={style.multiRowSkeleton} variant="rectangular" />
          </Box>) : null }
          <Typography sx={style.multiRowText}>
            {report ? `${numberToStringWithCommas(report.impressions || 0)}` : null}
          </Typography>
          {report ? (<Box sx={style.metricsBlock}>
            <Box sx={style.singleMetric}>
              <Typography sx={style.metricText}>
                {`${currency}${(report.cpm || 0).toFixed(2)}`}
              </Typography>
              <Typography sx={style.metricLabel}>
                {`CPM`}
              </Typography>
            </Box>
          </Box>) : null}
        </Box>
      </TableCell>

      <TableCell sx={{borderBottom: noDataToDisplay ? 'none' : '1px solid rgba(224, 224, 224, 1)'}}>
        <Box sx={style.rowCollection}>
          {isLoading ? (<Box sx={style.skeletonWrap}>
            <Skeleton sx={style.multiRowSkeleton} variant="rectangular" />
          </Box>) : null }
          <Typography sx={style.multiRowText}>
            {report ? `${numberToStringWithCommas(report.clicks || 0)}` : null}
          </Typography>
          {report ? (<Box sx={style.metricsBlock}>
            <Box sx={style.singleMetric}>
              <Typography sx={style.metricText}>
                {`${currency}${(report.cpc || 0).toFixed(2)}`}
              </Typography>
              <Typography sx={style.metricLabel}>
                {`CPC`}
              </Typography>
            </Box>
            <Box sx={style.singleMetric}>
              <Typography sx={style.metricText}>
                {`${parseCtr(report.ctr || 0)}%`}
              </Typography>
              <Typography sx={style.metricLabel}>
                {`CTR`}
              </Typography>
            </Box>
          </Box>) : null}
        </Box>
      </TableCell>

      <TableCell sx={{borderBottom: noDataToDisplay ? 'none' : '1px solid rgba(224, 224, 224, 1)'}}>

        <Box sx={style.rowCollection}>
          {isLoading ? (<Box sx={style.skeletonWrap}>
            <Skeleton sx={style.multiRowSkeleton} variant="rectangular" />
          </Box>) : null }

          {report && hasConversion(campaignType as string) ? (
            <>
              <Typography sx={style.multiRowText}>
                {`${numberToStringWithCommas(report.conversions || 0)}`}
              </Typography>
              <Box sx={style.metricsBlock}>
                <Box sx={style.singleMetric}>
                  <Typography sx={style.metricText}>
                    {`${currency}${(report.cpa).toFixed(2)}`}
                  </Typography>
                  <Typography sx={style.metricLabel}>
                    {`CPA`}
                  </Typography>
                </Box>
                <Box sx={style.singleMetric}>
                  <Typography sx={style.metricText}>
                    {`${report.cvr.toFixed(2)}%`}
                  </Typography>
                  <Typography sx={style.metricLabel}>
                    {`CVR`}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Typography sx={{...style.multiRowText, height: 20 }}></Typography>
              <Box sx={style.metricsBlock}>
                <Box sx={{...style.singleMetric, width: 60, borderTop: '1px solid #D9D9D9', height: 20}}>
                  <Typography sx={style.metricText}></Typography>
                  <Typography sx={style.metricLabel}></Typography>
                </Box>
                <Box sx={style.singleMetric}>
                  <Typography sx={style.metricText}></Typography>
                  <Typography sx={style.metricLabel}></Typography>
                </Box>
              </Box>
            </>)}
        </Box>
      </TableCell>

      <TableCell sx={{borderBottom: noDataToDisplay ? 'none' : '1px solid rgba(224, 224, 224, 1)'}}>
        <Box sx={style.rowCollection}>
          {isLoading ? (<Box sx={style.skeletonWrap}>
            <Skeleton sx={style.multiRowSkeleton} variant="rectangular" />
          </Box>) : null }
          <Typography sx={style.multiRowText}>
            {`${currency}${report.spent ? report.spent.toFixed(2) : 0}`}
          </Typography>
          {report ? (<Box sx={style.metricsBlock}>
            <Box sx={style.singleMetric}>
              <Typography sx={style.metricText}>
                {`${currency}${(report.today_spent || 0).toFixed(2)}`}
              </Typography>
              <Typography sx={style.metricLabel}>
                {`Today`}
              </Typography>
            </Box>
            <Box sx={style.singleMetric}>
              <Typography sx={style.metricText}>
                {`${currency}${(budget || 0).toFixed(2)}`}
              </Typography>
              <Typography sx={style.metricLabel}>
                {`Daily`}
              </Typography>
            </Box>
          </Box>) : null}

        </Box>
      </TableCell>

      <TableCell sx={{borderBottom: noDataToDisplay ? 'none' : '1px solid rgba(224, 224, 224, 1)'}}>
        <Box sx={style.rowMenu}>
          {isLoading ? (<Box sx={style.skeletonWrap}>
            <Skeleton sx={style.multiRowSkeleton} variant="rectangular" />
          </Box>) : null }
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation()
              handleMenuOpen(e)
            }}
          >
            <MoreVertIcon sx={style.moreVertIcon}/>
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {renderStatusItem(status_key as CampaignStatus) ? (
              <MenuItem dense onClick={(e) => {
                onStatusChange()
                e.stopPropagation();
                }
              }>
                {status_key === CampaignStatus.RUNNING ||
                status_key === CampaignStatus.RUNNING_DAILY_BUDGET_REACHED ? t('pause') : t('run')}
              </MenuItem>) : null
            }
            <MenuItem dense onClick={(e) => {
                onReplicate(e)
                // e.stopPropagation();
              }
            }>
              {t('replicate')}
            </MenuItem>
            <MenuItem dense onClick={(e) => {
               history.push(`${appPaths.reports}?campaignId=${id}`);
               e.stopPropagation();
             }
            }>
              {t('viewReport')}
            </MenuItem>
            {status_key !== CampaignStatus.ARCHIVED ? <MenuItem dense onClick={(e) => {
              archiveCampaign(id);
              e.stopPropagation();
            }}>
              {t('archive')}
            </MenuItem> : null}
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default CampaignListRow;