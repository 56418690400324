import { Box, Typography } from "@mui/material";
import { style } from './style';
import { getFileFromCache, i18n, Loading } from "../../../global";
import { LoginForm } from "../index";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getLoginFormValidation, LOGIN_FORM_DEFAULT_VALUES } from "../../utils";
import { LoginFormDTO } from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_TOKEN, AUTH_TOKEN_EXPIRY_DATE, REFRESH_TOKEN, roles, TAWK_API } from "../../../../_constants";
import React, { useState } from "react";
import { getUserByIdRequest } from "../../../../api";
import { IUser } from "../../../../types";
import { setAuthToken, setAuthUser } from "../../../store/reducers/authentication";
import { tawkInit } from "../../../../utils";
import { getWhiteLabelCompanyLogo } from "../../../store/reducers/admin";
import { appPaths, signInPaths } from "../../../../config/routes/paths";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";
import { setCurrentUser } from "../../../store/reducers/user";

function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, formState: { isSubmitting } } = useForm<LoginFormDTO>({
    defaultValues: LOGIN_FORM_DEFAULT_VALUES,
    resolver: yupResolver(getLoginFormValidation()) as any,
  });
  const { t } = useTranslation('translation', { keyPrefix: 'auth.login' });

  const history = useHistory();

  const whiteLabelLogo = useSelector(getWhiteLabelCompanyLogo);

  function onCreateAccountClick() {
    history.replace(signInPaths.signup);
  }

  const successCallback = (user: IUser) => {
    if (user.roles.includes(roles.WL_OWNER)) {
      return null;
    }
    tawkInit(TAWK_API, () => {});
  }

  async function onSubmit(data: LoginFormDTO) {
    const response = await AuthService.getToken(data);
    if (response?.data.data) {
      setLoading(true);
      const { access_token, refresh_token, expires_in, space_id } = response.data.data;
      const date = new Date();
      date.setSeconds(date.getSeconds() + expires_in);

      sessionStorage.setItem('spaceID', space_id);
      localStorage.setItem(AUTH_TOKEN, access_token);
      localStorage.setItem(REFRESH_TOKEN, refresh_token);
      localStorage.setItem(AUTH_TOKEN_EXPIRY_DATE, date.toISOString());
      dispatch(setAuthToken(access_token))

      getUserByIdRequest(space_id).then(res => {
        if(res?.data?.type?.code === StatusCode.OK) {
          const user = res?.data?.data;
          dispatch(setAuthUser(user));
          dispatch(setCurrentUser(user));
          i18n.changeLanguage(user.locale.split('_')[0]);
          successCallback(user)
          history.push(appPaths.campaigns);
        } else {
          NotificationService.handleError(res?.data?.type?.message);
        }
      })
    }
  }

  return loading ? <Loading /> : (
    <Box sx={style.view}>
      {whiteLabelLogo?.file_id && (
        <Box
          component='img'
          src={getFileFromCache(whiteLabelLogo.file_id)}
          alt='Company logo'
          sx={style.companyLogo}
        />
      )}
      <LoginForm control={control} onSubmit={handleSubmit(onSubmit)} disabled={isSubmitting} />
      <Typography
        sx={style.createAccount}
        data-testid='create-account'
        onClick={onCreateAccountClick}
      >{t('createAccount')}</Typography>
    </Box>
  )
}

Login.displayName = 'Login';

export default Login;