// export const paths = {
//   id: "/:id",
//   edit: "/edit",
//   create: "/create",
//   main: "/",
//   signup: "/auth/signup",
//   login: "/auth/login",
//   auth: "/auth",
//   confirmRegistration: "/auth/confirm-registration/:token",
//   inviteeRegistration: "/invitee-registration/:token",
//   forgotPassword: "/auth/forgot-password",
//   resetPassword: "/auth/reset-password/:key",
//   space: "/space",
//   campaigns: "/campaigns",
//   events: "/events",
//   myAccount: "/my-account",
//   users: "/users",
//   dashboard: "/dashboard",
//   payments: "/payments",
//   history: "/history",
//   inventory: "/inventory",
//   validate: "/validate",
//   placement: "/placement",
//   tokenValidation: "/token-validation",
//   spot: "/spot",
//   script: "/script",
//   types: "/types",
//   profile: "/profile",
//   profileAdmin: '/profile-whitelabel',
//   earnings: "/earnings",
//   redirectToBMP: "/redirect-bmp",
//   billing: "/payments/history",
//   admin: "/admin",
//   customization: "/customization",
//   income: "/income",
//   invoice: "/invoice",
//   invoiceUser: "/invoice/:id?",
//   userDetails: "/user/details/:id",
//   invoiceDetails: "/invoice/details/:id",
//   settings: "/settings",
//   moderation: "/moderation",
//   user: "/user",
//   legalEntity: "/legal-entity",
//   transactions: "/transactions",
//   accounts: "/accounts",
//   publishers: "/publishers",
//   type: "/type",
//   audience: "/audience",
//   audienceCreate: "/audience/create",
//   audienceEdit: "/audience/edit/:id",
//   audienceDetails: "/audience/details/:id",
//   audienceUnderConstruction: "/audience-under-construction",
// };

export const signInPaths = {
  login: "/auth/login",
  signup: "/auth/signup",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password/:key",
  confirmRegistration: "/auth/confirm-registration/:token",
}

export const appPaths = {
  events: "/events",
  profile: "/profile",
  reports: "/reports",
  settings: "/settings",
  audience: "/audiences",
  campaigns: "/campaigns",
  billing: "/payments/history",
  audienceEdit: "/audience/edit/:id",
  audienceCreate: "/audience/create",
  profileAdmin: '/profile-whitelabel',
  createCampaign: "/campaign/create",
  editCampaign: "/campaign/edit/:id",
  audienceDetails: "/audience/details/:id",
};

export const adminPaths = {
  accounts: "/admin/accounts",
  campaigns: "/admin/campaigns",
  moderation: "/admin/moderation",
  invoiceUser: "/admin/payments",
  customization: "/admin/customization",
  userDetails: "/admin/user/details/:id",
  invoiceDetails: "/admin/invoice/details/:id",
  moderationUser: "/admin/moderate/user/:id",
  legalEntityUser: "/admin/legal-entity/user/:id",
};

