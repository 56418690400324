import React, { Suspense } from "react";
import { Routes } from "./Routes";
import packageJson from "../package.json";
import "rc-dropdown/assets/index.css";
import "./reset.scss";
import "./App.css";
import { TopProgressBarProvider } from "./providers/TopProgressBarProvider";
import { ClientInfoContext } from "./providers/ClientInfoProvider";
import { MainContainer } from "./modules/global/components/MainContainer";
import { getClient } from "./utils";

console.log("Current version: " + packageJson.version);
console.log("intent.ai");

const App = () => {

  return (
    <>
      <TopProgressBarProvider />
      <div className="resize-warning">
        <p className="resize-warning-message">
          For having best user experience, please use desktop.
        </p>
      </div>
        <MainContainer>
          <ClientInfoContext.Provider value={{
            client: getClient(),
          }}>
            <Suspense fallback={null}>
              <Routes />
            </Suspense>
          </ClientInfoContext.Provider>
        </MainContainer>
    </>
  );
}

export default App;
