import React from "react";
import { Link } from "react-router-dom";
import "./AuthWrapper.scss";
import { signInPaths } from "../../../../config/routes/paths";

export const AuthWrapper: React.FC = ({ children }) => {
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-children">{children}</div>
      <div className="auth-wrapper-links">
        <Link to={signInPaths.login}>
          <span>{"LOGIN"}</span>
        </Link>
        <Link to={signInPaths.signup}>
          <span>{"CREATE AN ACCOUNT"}</span>
        </Link>
      </div>
    </div>
  );
};
