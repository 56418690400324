import React from "react";
import "./styles.css";
import { Sidebar } from "../Sidebar";

const Result = () => (
    <div className="not-found-wrapper">
        <Sidebar />
      <h1 style={{ margin: "auto" }}>
        <span>{"Page not found"}</span>
      </h1>
    </div>
);
export default Result;
