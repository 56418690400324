import { Box, InputAdornment, MenuItem, Select, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { abbreviateNumber, InfoIcon } from "../../../global";
import CheckIcon from "@mui/icons-material/Check";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReportService from "../../../report/services/report.service";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import "./style.scss";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";

interface IProps {
  control: Control<any>;
  setValue: SetFieldValue<any>;
  filtersConfig?: any[];

  onFilterChange?(filters: any): void;
}

type TSelectState = 'UNSELECTED' | 'WHITELIST' | 'BLACKLIST';

let debounceId: any = 0;

let selectedSet: Set<string> = new Set();

const resultViewer = (result: any[]) => {
  return result.length > 4 ? result.slice(0, 3) : result;
}

const SkeletonList = () => {
  return (
    <>
      {Array(10).fill(1).map( () => (
        <Box className={'app-select-body-left-item'}>
          <Skeleton sx={{backgroundColor: '#F4F6FA'}} variant="rectangular" animation={'wave'} width={"100%"} height={20} />
        </Box>))
      }
    </>
  )
}

const CustomTooltip = (props: any) => {
  const {children, item} = props;
  return (
    <Tooltip enterNextDelay={600} enterDelay={600} title={
      <Box className={'data-tooltip'}>
        <Box className={'tooltip-header'}>
          <Box className={'tooltip-header-title'}>
            <Typography>{item?.name}</Typography>
          </Box>
          {/*<Box className={'tooltip-header-link'}></Box>*/}
        </Box>
        <Box className={'tooltip-body'}>
          {item.site_or_app ? (<Box className={'tooltip-row'}>
            <Typography className={'row-key'}>{'Site or App:'}</Typography>
            <Typography className={'row-value'}>{item.site_or_app.join(', ') || 'n/a'}</Typography>
          </Box>) : null}
          {item.exchange_id ? (<Box className={'tooltip-row'}>
            <Typography className={'row-key'}>{'Exchange Id:'}</Typography>
            <Typography className={'row-value'}>{item.exchange_id.join(', ') || 'n/a'}</Typography>
          </Box>) : null}
          {item.os ? (<Box className={'tooltip-row'}>
            <Typography className={'row-key'}>{'OS:'}</Typography>
            <Typography className={'row-value'}>{item.os.join(', ') || 'n/a'}</Typography>
          </Box>) : null}
          {/*{item.category ? (<Box className={'tooltip-row'}>*/}
          {/*  <Typography className={'row-key'}>{'Category:'}</Typography>*/}
          {/*  <Typography className={'row-value'}>{item.category.join(', ') || 'n/a'}</Typography>*/}
          {/*</Box>) : null}*/}
          {/*{item.category_secondary ? (<Box className={'tooltip-row'}>*/}
          {/*  <Typography className={'row-key'}>{'Secondary Category:'}</Typography>*/}
          {/*  <Typography className={'row-value'}>{item.category_secondary.join(', ') || 'n/a'}</Typography>*/}
          {/*</Box>) : null}*/}
          {/*{item.country ? (<Box className={'tooltip-row'}>*/}
          {/*  <Typography className={'row-key'}>{'Country:'}</Typography>*/}
          {/*  <Typography className={'row-value'}>{item.country.join(', ') || 'n/a'}</Typography>*/}
          {/*</Box>) : null}*/}
        </Box>
        <Box className={'tooltip-footer'}>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Interstitial'}</Typography>
            <Typography className={'row-value'}>{item.interstitial ? item.interstitial.join(', ') : 'n/a'}</Typography>
          </Box>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Users Count'}</Typography>
            <Typography className={'row-value'}>{item.user_count ? abbreviateNumber(item.user_count) : 'n/a'}</Typography>
          </Box>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Impression Count'}</Typography>
            <Typography className={'row-value'}>{item.impression_count ? abbreviateNumber(item.impression_count) : 'n/a'}</Typography>
          </Box>
        </Box>
        <Box className={'tooltip-footer-creatives'}>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Creative Position'}</Typography>
            <Typography className={'row-value'}>{item.creative_position ? item.creative_position.join(', ') : 'n/a'}</Typography>
          </Box>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Creative Type'}</Typography>
            <Typography className={'row-value'}>{item.creative_type ? item.creative_type.join(', ') : 'n/a'}</Typography>
          </Box>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Creative Size'}</Typography>
            <Typography className={'row-value'}>{item.creative_size ? item.creative_size.join(', ') : 'n/a'}</Typography>
          </Box>
        </Box>
      </Box>}>
      {children}
    </Tooltip>
  )
}

// const parseCtr = (ctr: number) => ctr.toFixed(2);

const AppsSitesSelectBox = (props: IProps) => {
 const {
   filtersConfig = [],
   control,
  setValue
} = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.placement',
  });

  const [excluded_apps, included_apps, country] = useWatch({
    control,
    name: ['placement.excluded_apps', 'placement.included_apps', 'country'],
  });


  const scrollRef = useRef();
  const [selectState, setSelectState] = useState<TSelectState>('UNSELECTED');
  const [openAppBox, setOpenAppBox] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<any>({page: 1, page_size: 150});
  const [placements, setPlacements] = useState<any[]>([]);

  function getReportPlacement(filters: any) {
    setLoading(true)
    ReportService
      .getPlacementsReport({...filters, country: country})
      .then(response => {
        if(response?.data?.type?.code === StatusCode.OK){
          setPlacements(response?.data?.data?.data);
          setLoading(false);
        } else {
          NotificationService.handleError(response?.data?.type?.message);
        }
      });
  }

  const handleSearch = (value: any) => {
    !openAppBox && setOpenAppBox(true);
    clearTimeout(debounceId);
    debounceId = setTimeout(() => {
      getReportPlacement({...formValues, name: value, page: 1})
      setFormValues({...formValues, name: value, page: 1})
    }, 1000);
  };

  const handleChange = (value: any, name: string) => {
    getReportPlacement({...formValues, [name]: value, page: 1})
    setFormValues({...formValues, [name]: value, page: 1});
  };

  const handleScroll = () => {

    if(scrollRef.current){
      const {scrollTop, scrollHeight, clientHeight} = scrollRef.current;

      if(!loading && !isPending && (clientHeight + scrollTop === scrollHeight)){
        ReportService
          .getPlacementsReport({...formValues, page: formValues.page + 1, country: country})
          .then(response => {
            if(response?.data?.type?.code === StatusCode.OK){
              setPlacements([...placements, ...response?.data?.data?.data]);
              setIsPending(false);
            } else {
              NotificationService.handleError(response?.data?.type?.message);
            }
          });
        setIsPending(true);
        setFormValues({...formValues, page: formValues.page + 1});
      }
    }
  }


  const handleSelect = (selected: any, type: TSelectState) => {
    selectedSet.add(selected.name);
    setResult([...result, {name: selected.name, value: selected.site_or_app[0], site_or_app: selected.site_or_app, impression_count: selected.impression_count }]);
    selectState !== type && setSelectState(type)
  };

  const handleDelete = (selected: any) => {
    const _result = result.filter(item => item.name !== selected.name);
    selectedSet.delete(selected.name);
    setResult(_result);
    !_result.length && setSelectState('UNSELECTED');
  };

  useEffect(() => {
    getReportPlacement(formValues);

    if(included_apps?.length){
      setSelectState('WHITELIST')
    } else if (excluded_apps?.length) {
      setSelectState('BLACKLIST')
    }
    const _selectedList = [ ...excluded_apps, ...included_apps];
    selectedSet = new Set(_selectedList.map(i => i.name));
    setResult(_selectedList)

    return () => {
      setPlacements([]);
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    switch(selectState) {
      case 'WHITELIST' : {
        setValue('placement.included_apps', result);
        break;
      }
      case 'BLACKLIST' : {
        setValue('placement.excluded_apps', result);
        break;
      }
      default : {
        setValue('placement.included_apps', []);
        setValue('placement.excluded_apps', []);
      }
    }
    // eslint-disable-next-line
  }, [result])

  return (
    <Box className={'apps-sites-select-box'}>
      <TextField
        onClick={() => setOpenAppBox(!openAppBox)}
        className={'apps-search-input border-color-E6E9ED'}
        onChange={(event) => handleSearch(event.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
        }}
      />
      {openAppBox ? (
        <Box className={'app-select-wrap'}>
          {isPending ? (<Box className={'app-select-wrap-loading'}>
            <SkeletonList />
          </Box>) : null}
          <Box className={'app-select-header'}>
            {filtersConfig.map(({name, labelKey, dataList}) => (
              <Select
                key={name}
                sx={{ minWidth: 60, backgroundColor: 'transparent', marginRight: '30px' }}
                variant="standard"
                displayEmpty
                IconComponent={ExpandMoreIcon}
                renderValue={formValues[name as keyof typeof formValues] ? undefined : () => t(labelKey)}
                disableUnderline
                id={name}
                title={dataList.filter(({label, value}: any)  => value === formValues[name as keyof typeof formValues])[0]?.label}
                value={formValues[name as keyof typeof formValues]}
                onChange={(e) => handleChange(e.target.value, name)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                  dataList.map(({label, value}: any) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))
                }
              </Select>
            ))}
            <Box
              className={'app-select-header-icons-delete'}
              onClick={() => setOpenAppBox(false)}
            >
              <CloseIcon />
            </Box>
          </Box>

          <Box className={'app-select-body'}>
            <Box className={'app-select-body-left'} onScroll={handleScroll} ref={scrollRef}>
              {loading ? (<SkeletonList />) : placements.map((item) => selectedSet.has(item.name) ? null : (
                  <Box key={item.name} className={'app-select-body-left-item'}>
                    <CustomTooltip item={item}>
                      <Typography className={'app-select-body-left-item-label'}>
                        {item.name}
                      </Typography>
                    </CustomTooltip>
                    <Typography className={'app-select-body-left-item-text'}>
                    {abbreviateNumber(item.impression_count)}
                    </Typography>
                    <Tooltip title={'dailyAmountTooltip'}>
                      <InfoIcon />
                    </Tooltip>
                    <Box className={'app-select-body-left-item-select-icons'}>
                      {selectState === 'UNSELECTED' || selectState === 'BLACKLIST' ? (
                      <Box
                      className={'app-select-body-left-item-select-icons-block'}
                      onClick={() => handleSelect(item, 'BLACKLIST')}
                      >
                        <BlockRoundedIcon />
                      </Box>) : null}
                    {selectState === 'UNSELECTED' || selectState === 'WHITELIST' ? (
                      <Box
                        className={'app-select-body-left-item-select-icons-check'}
                        onClick={() => handleSelect(item, 'WHITELIST')}
                      >
                        <CheckIcon color={"success"} />
                      </Box>) : null}
                    </Box>
                  </Box>)
              )}
            </Box>
            <Box className={'app-select-body-right'}>
              {result.length ? (
                <Box className={'app-select-body-right-header'}>
                  <Typography>
                    {t(selectState)}
                  </Typography>
                </Box>) : null
              }
              <Box className={'app-select-body-right-body'}>
                {
                  result.map((item) => (
                    <Box key={item.name} className={'app-select-body-right-item'}>
                      <CustomTooltip item={item}>
                        <Typography title={item.name} className={'app-select-body-left-item-label'}>
                          {item.name}
                        </Typography>
                      </CustomTooltip>
                      {item.impression_count ? (
                        <>
                          <Typography className={'app-select-body-left-item-text'}>
                            {abbreviateNumber(item.impression_count)}
                          </Typography>
                          <Tooltip title={'dailyAmountTooltip'}>
                            <InfoIcon />
                          </Tooltip>
                        </>) : null
                      }
                      <Box className={'app-select-body-right-item-select-icons'}>
                        <Box
                          className={'app-select-body-right-item-select-icons-delete'}
                          onClick={() => handleDelete(item)}
                        >
                          <CloseIcon />
                        </Box>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
      ) : result.length ? (
        <Box className={'chips-view-box'}>
          <Box className={'chips-view-box-label'}>
            {`${t(selectState)}:`}
          </Box>
          <Box className={'chip-container'}>
            { resultViewer(result).map(item => (
              <Typography key={item.name} title={item.name} className={`chip ${result.length > 4 ? 'moreChips' : ''}`}>
                {item.name}
              </Typography>
            ))
            }
            {result.length > 4 ? (
              <Typography onClick={() => setOpenAppBox(true)} className={'view-more'}>
              {`+${ result.length - 3 }`}
              </Typography>) : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default AppsSitesSelectBox;

