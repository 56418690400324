import { Box, Button, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { IntFieldController } from "../../../global";
import { Control } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FormEvent } from "react";
import { signInPaths } from "../../../../config/routes/paths";

type Props = {
  control: Control<any>;
  onSubmit: (event: FormEvent<HTMLDivElement>) => void;
  disabled: boolean;
}

function LoginForm({ control, onSubmit, disabled }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'auth.login' });

  const history = useHistory();

  function onForgotPasswordClick() {
    history.replace(signInPaths.forgotPassword);
  }

  return (
    <Box sx={style.form} component='form' onSubmit={onSubmit}>
      <Typography sx={style.welcome}>{t('welcomeBack')}</Typography>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('login')}</Typography>
        <IntFieldController
          control={control}
          name='username'
          sx={style.fieldInput}
          placeholder={t<string>('login')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('password')}</Typography>
        <IntFieldController
          control={control}
          name='password'
          sx={style.fieldInput}
          placeholder={t<string>('password')}
          type='password'
        />
      </Box>
      <Button
        variant='contained'
        sx={style.login}
        type='submit'
        disabled={disabled}
      >
        {t('submitTitle')}
      </Button>
      <Button sx={style.forgotPassword} data-testid='forgot-password' onClick={onForgotPasswordClick}>
        {t('forgotPassword')}
      </Button>
    </Box>
  );
}

LoginForm.displayName = 'LoginForm';

export default LoginForm;