import { Box, Typography } from "@mui/material";
import { style } from './style';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';


interface Props {
  files: any[];
  onRemove: (name: string) => void;
  onDownload: (name: string) => void;
}

function WhiteListFiles({ files, onRemove, onDownload }: Props) {

  return (
    <Box>
      {files.map((name: string, i) => {
        return (
          <Box key={i} sx={style.fileBox}>
            <Typography sx={style.fileName} onClick={() => onDownload(name)}>{name}</Typography>
            <DeleteForeverTwoToneIcon sx={style.deleteIcon} onClick={() => onRemove(name)}/>
          </Box>
      )})}
    </Box>
  )
}

WhiteListFiles.displayName = 'WhiteListFiles';

export default WhiteListFiles;