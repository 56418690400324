/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/30/2023
 * All Rights Reserved.
 */
import { Box, Button, debounce, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AudienceService } from "../../../audience/services";
import { Website } from "../../../audience/types/audience";
import {
  abbreviateNumber,
  CrossIcon,
  IntAutocomplete,
  IntFieldController,
  // PendingIcon,
  ProfileIcon, spin,
} from "../../../global";
import { useFormContext, useWatch } from "react-hook-form";
import iconPlaceholder from '../../../../assets/images/ic_placeholder.png';
import { style } from './style';
import { useTranslation } from "react-i18next";
import { CampaignService } from "../../services";
import { StatusCode } from "../../../global/types";
import { useParams } from "react-router-dom";

const progressBar = {
  borderRadius: '50%',
  border: '3px solid transparent',
  borderTopColor: '#326afc6e',
  borderRightColor: '#326afc6e',
  animation: `${spin} 1s linear infinite`,
  width: 15,
  height: 15,
  top: '12px',
  right: '36px',
  position: 'absolute',
}

export type Props = {
  name: string;
  country?: string;
  placeholder: string;
}

const CustomTooltip = (props: any) => {
  const {children, item} = props;
  return (
    <Tooltip enterNextDelay={600} enterDelay={600} title={
      <Box className={'data-tooltip'}>
        <Box className={'tooltip-header'}>
          <Box className={'tooltip-header-title'}>
            <Typography>{item?.name}</Typography>
          </Box>
        </Box>
        <Box className={'tooltip-body'}>
          {item.bundle_ids ? (<Box className={'tooltip-row'}>
            <Typography className={'row-key'}>{'Bundle Ids:'}</Typography>
            <Typography className={'row-value'}>{item.bundle_ids.join(', ') || 'n/a'}</Typography>
          </Box>) : null}
        </Box>
        <Box className={'tooltip-footer'}>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Type'}</Typography>
            <Typography className={'row-value'}>{item.type ? item.type : 'n/a'}</Typography>
          </Box>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Users Min'}</Typography>
            <Typography className={'row-value'}>{item.users_min ? abbreviateNumber(item.users_min) : 'n/a'}</Typography>
          </Box>
          <Box className={'tooltip-footer-item'}>
            <Typography className={'row-key'}>{'Users Max'}</Typography>
            <Typography className={'row-value'}>{item.users_max ? abbreviateNumber(item.users_max) : 'n/a'}</Typography>
          </Box>
        </Box>
      </Box>}>
      {children}
    </Tooltip>
  )
}

function SearchSites({ name, placeholder, country=''}: Props) {
  const [value, setValue] = useState('');

  const { control, setValue: setFieldValue } = useFormContext();

  const { t } = useTranslation();

  const [websiteList, setWebsiteList] = useState<{ isLoading: boolean; data: Array<Website>; }>({
    isLoading: false,
    data: [],
  });

  const [resultFound, setResultFound] = useState(true);

  const { id } = useParams<{ id?: string; }>();

  const apps = useWatch({
    name,
    control,
  });

  const searchWebsites = useCallback(debounce((searchTerm: string) => { // eslint-disable-line
    setWebsiteList({
      isLoading: true,
      data: [],
    });
    setResultFound(true);
    AudienceService.getAudienceResource(searchTerm, country)
      .then(({ data }) => {
        if (Array.isArray(data.data)) {
          // const resources = data.data.filter((app: Website) => app.users_min && app.users_max)
          const resources = data.data.map((app: Website) => ({ ...app, value: app.id }));

          if (!resources.length) {
            setResultFound(false);
          }
          setWebsiteList({
            isLoading: false,
            data: resources,
          });
        }
      });
  }, 300), []);

  useEffect(() => {
    if (value) {
      searchWebsites(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps


  function removeWebsiteFromList(id: string) {
    setFieldValue(name, apps.filter((app: Website) => app.id !== id));
  }

  async function addWebsiteToList() {
    const response = await CampaignService.addResource({
      name: value,
      id,
    });
    if (response.data.type?.code == StatusCode.OK && response.data.data) { // eslint-disable-line
      setFieldValue(name, [...apps, { name: response.data.data.name, id: response.data.data.id  }]);
      setValue('');
    }
  }

  // function isAppDefined(app: Website) {
  //   return app.category_id || app.users_max || app.users_min || app.description || app.icon;
  // }

  // function hasUserInfo(app: Website) {
  //   return !!(app.users_max || app.users_min);
  // }

  return (
    <>
      {!!apps.length && (
        <Box sx={style.appList}>
          {apps.map((app: Website) => (
            <CustomTooltip key={app.id || app.name} item={app}>
              <Box  sx={[style.app, style.appInList]}>
                <Box
                  component='img'
                  src={app.icon || iconPlaceholder}
                  alt={app.description}
                  sx={style.image}
                />
                <Box sx={style.appInfo}>
                  <Typography sx={style.appTitle}>{app.name}</Typography>
                </Box>
                <CrossIcon style={style.crossIcon} onClick={() => removeWebsiteFromList(app.id)}/>
              </Box>
            </CustomTooltip>
          ))}
        </Box>
      )}
      <Box sx={{position: 'relative'}}>
        {websiteList?.isLoading ? <Box component={'span'} sx={progressBar} /> : null}
        <IntFieldController
          control={control}
          name={name}
          Component={IntAutocomplete}
          renderTags={() => null}
          placeholder={placeholder}
          onInputChange={(e, newValue, reason) => {
            if (reason === 'input') {
              setValue(newValue);
            }}
          }
          options={websiteList.data}
          inputValue={value}
          getOptionLabel={(option) => option.name}
          multiple
          clearIcon={null}
          onClose={() => setValue('')}
          // disableCloseOnSelect
          renderOption={(props, option) => (
            <li {...props}>
              <Box sx={style.label}>
                <Box
                  component='img'
                  src={option.icon || iconPlaceholder}
                  alt={option.description}
                  sx={style.image}
                />
                <Typography sx={style.optionName}>{option.name}</Typography>
              </Box>
              <Box sx={style.numbers}>
                <ProfileIcon />
                <Typography>
                  {abbreviateNumber(option.users_min || 0)} - {abbreviateNumber(option.users_max || 0)}
                </Typography>
              </Box>
            </li>
          )}
          sx={style.autocomplete}
          disableClearable
          noOptionsText={(!resultFound && value) ? (
            <Box sx={style.noOptionBox}>
              <Typography sx={style.noItemFound}>
                {t('campaign.targeting.weDontHaveInfo')}
                <Typography sx={style.itemText} component='span'>"{value}"</Typography>
                {t('campaign.targeting.weCanProvide')}
              </Typography>
              <Button onClick={addWebsiteToList}>{t('campaign.targeting.requestResearch')}</Button>
            </Box>
          ) : t('campaign.targeting.searchForOptions')}
          />
      </Box>
    </>
  )
}

export default SearchSites;