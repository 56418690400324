import { style } from "./style";
import { Box, IconButton, Typography } from "@mui/material";
import {
  AppIcon,
  appObjectStore,
  AwarenessGoalIcon,
  CrossIcon,
  VoiceIcon,
  WebsiteIcon,
} from "../../../global";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import "./style.scss";
import onceOnly from "../../../global/utils/once-only";
import { useHistory } from "react-router-dom";
import { CampaignObjectiveType } from "../../types";
import { isDev, isLocal, isNG } from "../../../../_constants/contentType";
import { appPaths } from "../../../../config/routes/paths";

type Props = {
  handleModalClose: () => void;
}

const TYPES = [
  {
    id: 0,
    title: t('campaign.goal.type.awareness'),
    description: t('campaign.goal.visibilityDescription'),
    Icon: AwarenessGoalIcon,
    value: CampaignObjectiveType.AWARENESS,
  },
  {
    id: 1,
    title: t('campaign.goal.type.consideration'),
    description: t('campaign.goal.considerationDescription'),
    Icon: AppIcon,
    value: CampaignObjectiveType.CONSIDERATION,
  },
  {
    id: 2,
    title: t('campaign.goal.type.conversion'),
    description: t('campaign.goal.conversionDescription'),
    Icon: WebsiteIcon,
    value: CampaignObjectiveType.CONVERSION,
  },
];

if(isNG || isLocal || isDev) {
  TYPES.push({
    id: 3,
    title: t('campaign.goal.type.audio'),
    description: t('campaign.goal.audioDescription'),
    Icon: VoiceIcon,
    value: CampaignObjectiveType.OTHER,
  },)
}

function CampaignObjective({ handleModalClose }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.goal' });
  const history = useHistory();

  function handleTypeChange(type: CampaignObjectiveType) {
    onceOnly.setParam('campaignType', type);
    appObjectStore.setObject('isAudioAd',type === CampaignObjectiveType.OTHER);
    setTimeout(() => {
       history.replace(`${appPaths.createCampaign}?tab=basicInfo`);
    },0);
  }

  return (
    <Box className={'campaign-type'}>
      <Box className={'campaign-type-header'}>
        <Typography className={'campaign-type-header-title'}>
          {t('chooseGoals')}
        </Typography>
        <IconButton size='small' className={'campaign-type-header-close-icon'} onClick={handleModalClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Box className={'campaign-type-body'}>
        {TYPES.map((type, index, arr) => (
          <Box
            key={type.id}
            className={'campaign-type-body-item'}
            onClick={() => handleTypeChange(type.value)}
          >
            <Box className={'campaign-type-body-item-container'}>
              <Box sx={[style.iconWrapper]}>
                <type.Icon sx={style.icon} viewBox='0 0 36 36' />
              </Box>
              <Box sx={style.titleWrapper}>
                <Typography sx={style.goalTitle}>
                  {type.title}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography className={'campaign-type-body-item-description'}>
                {type.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

CampaignObjective.displayName = 'CampaignObjective';

export default CampaignObjective;