import React, { useState } from "react";
import { IAdminSearchResultCampaign, IAdminSearchResultAccount } from "../../../../types";
import "./style.scss";
import { AdminCampaign } from "../AdminCampaign";
import { AdminAccount } from "../AdminAccount";
import { CoreTabs, TabBody } from "../../../global/components/CoreTabs";

interface IProps {
  campaigns: IAdminSearchResultCampaign[];
  accounts: IAdminSearchResultAccount[];
  query: string;
  allResult: any;
  onAdminChange: Function;
  onCampaignChange: Function;
}

const AdminSearchView = (props: IProps) => {
  const {
    query,
    allResult,
    campaigns,
    accounts,
    onAdminChange,
    onCampaignChange,
  } = props;

  const [selected, setSelected] = useState(0);


  return allResult.length === 0 ? (
    <div className="admin-search-view">
      <p className="admin-search-view-no-result-found">
        <span>{"No results found"}</span>
      </p>
    </div>
  ) : (
    <div className="admin-search-view">
      <div>
        <p className="admin-search-view-query">
          <span>{`Search results for ${query}`}</span>
        </p>
      </div>

      <CoreTabs
        className={'admin-search-view-tabs fill'}
        config={[
          {label: `All (${allResult.length})`},
          {label: `Accounts (${accounts.length})`},
          {label: `Campaigns (${campaigns.length})`},
        ]} variant="fullWidth"
        selectedTab={selected}
        onTabSelect={ setSelected }
      />
      <div className="admin-search-view-content">
        <TabBody selectedTab={selected} tabKey={0}>
          <ul className="admin-search-list">
            {allResult.map((item: any) => {
              if (item.type_key === 1) {
                return (
                  <AdminCampaign
                    key={item.campaign_id}
                    campaign={item}
                    campaignDetailShow
                    onCampaignChange={onCampaignChange}
                  />
                );
              } else if (item.type_key === 2) {
                return (
                  <AdminAccount
                    account={item}
                    key={item.space_id}
                    onAdminChange={onAdminChange}
                  />
                );
              }
              return null;
            })}
          </ul>
        </TabBody>
        <TabBody selectedTab={selected} tabKey={1}>
          <ul className="admin-search-list">
            {accounts.length !== 0 ? (
              accounts.map(account => (
                <AdminAccount
                  account={account}
                  key={account.space_id}
                  onAdminChange={onAdminChange}
                />
              ))
            ) : (
              <div className="text-center mt-1">
                <span>{"No Accounts Found"}</span>
              </div>
            )}
          </ul>
        </TabBody>
        <TabBody selectedTab={selected} tabKey={2}>
          <ul className="admin-search-list">
            {campaigns.length !== 0 ? (
              campaigns.map(campaign => (
                <AdminCampaign
                  key={campaign.campaign_id}
                  campaign={campaign}
                  campaignDetailShow
                  onCampaignChange={onCampaignChange}
                />
              ))
            ) : (
              <div className="text-center mt-1">
                <span>{"No Campaigns found"}</span>
              </div>
            )}
          </ul>
        </TabBody>
      </div>
    </div>
  );
}

export default AdminSearchView;
