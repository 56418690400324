import React from "react";
import { Redirect, Switch, BrowserRouter, Route } from "react-router-dom";
import { adminRoutesConfig, appRoutesConfig, defaultPath, notFoundConfig } from "./config/routes/route-config";
import { useSelector } from "react-redux";
import { roles } from "./_constants";
import { authUserSelector } from "./modules/store/reducers/authentication";

const redirectToNotfound = () => <Redirect to={defaultPath} />;

export const Routes = () => {
  const authUser = useSelector(authUserSelector);

  return (
    <BrowserRouter>
      <Switch>
        {localStorage.getItem('DSP_AUTH_TOKEN') && appRoutesConfig.map(item => <Route key={item.path} path={item.path} component={item.component} />)}
        {authUser?.roles.includes(roles.WL_OWNER) && adminRoutesConfig.map(item => <Route key={item.path} path={item.path} component={item.component} />)}
        <Route exact path="/notfound" component={notFoundConfig} />
        <Route render={redirectToNotfound} />
      </Switch>
    </BrowserRouter>
  );
};
