/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/12/2023
 * All Rights Reserved.
 */
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import './style.scss'
import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { currentUserSelector } from "../../../store/reducers/user";
import AdminUserSearch from "../../../adminApp/modules/payment/components/AdminInvoiceList/AdminUserSearch";
import { CrossIcon } from "../../components";

type Props = {
  onClose: () => void;
  onReplicate: (campaignId: string, userId) => void;
  campaignId: string;
}

function ReplicateCampaignModal({ onClose, onReplicate, campaignId }: Props) {

  const currentUser = useSelector(currentUserSelector);

  const adminUserSearchRef = useRef(null);
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.list' });
  const [replicateCampaignUser, setReplicateCampaignUser] = useState<Nullable<any>>(null)


  // const onInit = () => {
  //   const name = `${currentUser?.first_name} ${currentUser?.last_name}`
  //   if(adminUserSearchRef && adminUserSearchRef.current) {
  //     adminUserSearchRef.current.outsideAccountSet({
  //       space_name: name,
  //       currency: currentUser?.currency,
  //       space_id: currentUser?.id
  //     });
  //   }
  // }

  const onAccountSelect = (account: Nullable<any>) => {
    if(replicateCampaignUser?.space_id !== account?.space_id){
      setReplicateCampaignUser(account);
    }
  }

  // useEffect(() => onInit(),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []);

  return (
    <Paper className={'replicate-campaign-modal'}>
      <Box className={'replicate-campaign-Header'}>
        <Typography className={'title'}>{t('replicateCampaignModalTitle')}</Typography>
        <IconButton size='small' className={'icon-btn'} onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Box className={'replicate-campaign-body'}>
        <Typography className={'description'}>{t('replicateCampaignModalDescription')}</Typography>
        <AdminUserSearch ref={adminUserSearchRef} initId={currentUser?.id} onUserSelect={(account) => onAccountSelect(account)}/>
      </Box>
      <Box className={'replicate-campaign-footer'}>
        <Button
            onClick={() => onReplicate(campaignId, replicateCampaignUser ? replicateCampaignUser.space_id : currentUser?.id)}
            className={'button'}
            variant='contained'
          >{t('replicate')}</Button>
        </Box>
    </Paper>
  )
}

export default ReplicateCampaignModal;