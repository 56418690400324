import React from "react";
import "./style.scss";

interface IProps {
  id: string;
  defaultMessage: string;
  staticText?: string;
  data?: any;
}

export function InputErrorMessage(props: IProps) {
  const {defaultMessage, staticText } = props;
  return (
    <div className="input-error-message">
      {staticText ? (
        staticText
      ) : (
        <span>{defaultMessage}</span>
  )
}
</div>
)
  ;
}

