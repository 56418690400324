import "./style.scss"
import { useTranslation } from "react-i18next";
import {
  debounce, Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow, TableSortLabel,
} from "@mui/material";
import { style } from './style';
import { CampaignsList } from "../../types";
import CampaignListRow from "../CampaignListRow";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { CampaignService } from "../../services";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";
import { CampaignListFilter } from "../index";
import NoCampaigns from "../NoCampaigns";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../global";
import { useForm } from "react-hook-form";
import { CAMPAIGN_LIST_DEFAULT_FILTER } from "../../utils";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import StraightIcon from '@mui/icons-material/Straight';
import { AUTH_TOKEN } from "../../../../_constants";
import { appPaths } from "../../../../config/routes/paths";
import { AuthTitleWrapper } from "../../../global/components/AuthTitleWrapper";
import { CURRENCYICONS } from "../../../store";
import { currentUserSelector, setBalance, setBalanceLoad, setCurrentUser } from "../../../store/reducers/user";
import { logout } from "../../../../utils/auth.utils";
import ReplicateCampaignModal from "../../../global/modals/replicateCampaignModal";
import { setHasCampaign } from "../../../store/reducers/campaign";
import { getCurrentSpaceBalanceRequest, getUserByIdRequest } from "../../../../api";

const baseData = (function(count: number){
  const fakeData = [];
  for(let i = 1; i <= count; i++) {
    fakeData.push({
      id: i.toString(),
      status_key: 0,
      name: "",
      campaign_start_at: "",
      campaign_type_key: 1,
      daily_budget_spent: 0,
      impressions: 0,
      clicks: 0,
      budget_spent: 0,
      campaignType: "'WEB STANDARD CONVERSION'",
      budget_type_key: 1,
      budget: 0,
      report_data: {cpa: 0, cvr: 0}
    })
  }
  return fakeData;
})(10);

let noDataToDisplay = false;

const initialState = {
  field: '',
  asc: false,
}

function CampaignList() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.list' });

  const { control, getValues, setValue } = useForm({
    defaultValues: CAMPAIGN_LIST_DEFAULT_FILTER,
  });

  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [openReplicateModal, setOpenReplicateModal] = useState<Nullable<string>>(null);


  const [campaigns, setCampaigns] = useState<CampaignsList>({
    data: baseData,
    totalCount: 0,
    isLoading: true,
  });
  const currentUser = useSelector(currentUserSelector);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sorting, setSorting] = useState(initialState);
  const searchParams = useQuery();
  const page = Number(searchParams.get('page')) || 0;

  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCampaigns = useCallback(debounce(function getCampaigns(data) {
    if(data.fromFiler) {
      setSorting({field: '', asc: false});
      delete data.fromFiler;
    }
    setCampaigns(data => ({
      ...data,
      isLoading: true,
      // data: [],
    }));
    CampaignService.getCampaigns({ ...data, page: page + 1, pageSize: rowsPerPage })
      .then((response) => {
        const camps = response?.data;
        if (camps?.type?.code === StatusCode.OK) {
          noDataToDisplay = camps?.data?.data?.length === 0;
          const _data = camps?.data?.data?.length !== 0 ? camps?.data?.data : baseData;
          setCampaigns({
            data: _data,
            totalCount: camps?.data?.total_size,
            isLoading: false,
          });
        } else {
          NotificationService.handleError(camps?.type?.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 200), [page, rowsPerPage]);


  async function archiveCampaign(campaignId: string) {
    setCampaigns({...campaigns, isLoading: true})
    const response = await CampaignService.archiveCampaign(campaignId);
    // eslint-disable-next-line
    if (response?.data?.type?.code == StatusCode.OK) {
      NotificationService.handleSuccess(t('archived'));
      const fields = getValues();
      getCampaigns({
        from_date: fields.date?.from,
        to_date: fields.date?.to,
        name: fields.name,
        status_filter: fields.status_filter,
        space_id: currentUser.id
      });
    } else {
      NotificationService.handleError(response?.data?.type?.message);
      setCampaigns({...campaigns, isLoading: false})
    }
  }

  function handleSorting(orderField: string, orderAsc: boolean ): void {
    const fields = getValues();
    getCampaigns({
      from_date: fields.date?.from,
      to_date: fields.date?.to,
      name: fields.name,
      status_filter: fields.status_filter,
      order_field: orderField,
      order_asc: orderAsc,
      space_id: currentUser.id
    });
  }

  function updateCampaignList(): void {
    const fields = getValues();
    getCampaigns({
      from_date: fields.date?.from,
      to_date: fields.date?.to,
      name: fields.name,
      status_filter: fields.status_filter,
      order_field: sorting.field,
      order_asc: sorting.asc,
      space_id: currentUser.id
    });
  }

  const setAdminNewSpace = (id: string, callBack ) => {
    getUserByIdRequest(id).then( async (res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        const user = res?.data?.data;
        dispatch(setCurrentUser(user));
        sessionStorage.setItem('spaceID', user.id);

        const countRes = await CampaignService.getSpaceCampaignCountRequest(user.id)
        dispatch(setHasCampaign(!!countRes?.data?.data));

        dispatch(setBalanceLoad(true));
        const balanceRes = await getCurrentSpaceBalanceRequest(user.id);
        dispatch(setBalance(balanceRes?.data?.data));
        dispatch(setBalanceLoad(false));

        callBack && callBack()
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })

  }


  function handleChangePage(event: unknown, newPage: number) {
    history.replace(`${appPaths.campaigns}?page=${newPage}`);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(event.target.value));
  }

  function replicateCampaign(id: string, userId: string) {
    const callBack = () => {
      history.replace(`${appPaths.createCampaign}?replicatedId=${id}`);
    }
    if(userId !== currentUser?.id){
      setAdminNewSpace(userId, callBack);
    } else {
      history.replace(`${appPaths.createCampaign}?replicatedId=${id}`);
    }
  }

  function handleCreationModalOpen() {
    setCreationModalOpen(true);
  }

  function handleCreationModalClose() {
    setCreationModalOpen(false);
  }
  
  useEffect(() => {
    const page = Number(searchParams.get('page')) || 0;
    sessionStorage.setItem('referrer', `${appPaths.campaigns}?page=${page}`);
  }, [searchParams]);

  useEffect(() => {
    handleSorting(sorting.field, sorting.asc);
    // eslint-disable-next-line
  }, [sorting.field, sorting.asc]);

  useEffect(() => {
    if(!localStorage.getItem(AUTH_TOKEN)){
     logout();
    }
    return () => {
      setCampaigns({
        data: baseData,
        totalCount: 0,
        isLoading: true,
      })
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthTitleWrapper
      className={'campaign-list-wrap'}
      title={t('title')}
      iconName="campaign"
    >
      <CampaignListFilter
        setValue={setValue}
        getCampaigns={getCampaigns}
        userId={currentUser.id}
        control={control}
        modalOpen={creationModalOpen}
        handleModalOpen={handleCreationModalOpen}
        handleModalClose={handleCreationModalClose}
      />
      <Paper>
        <TableContainer className={'campaign-list'} sx={style.tableContainer}>
          <Table>
            <TableHead sx={{backgroundColor: '#F4F6FA'}}>
              <TableRow>
                <TableCell size='small' sx={{...style.headCell, minWidth:'430px', paddingLeft: '16px',textAlign: 'start !important'}}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'name' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'name'}
                    direction={sorting.field === 'name' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => {
                      setSorting({field: 'name', asc: !sorting.asc})
                      }
                    }
                  >
                  {t('head.name')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={{...style.headCell}}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'impressions' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'impressions'}
                    direction={sorting.field === 'impressions' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'impressions', asc: !sorting.asc})}
                  >
                    {t('head.impressions')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={style.headCell}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'clicks' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'clicks'}
                    direction={sorting.field === 'clicks' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'clicks', asc: !sorting.asc})}
                  >
                    {t('head.clicks')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={style.headCell}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'conversions' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'conversions'}
                    direction={sorting.field === 'conversions' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'conversions', asc: !sorting.asc})}
                  >
                    {t('head.conversions')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={style.headCell}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'spent' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'spent'}
                    direction={sorting.field === 'spent' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'spent', asc: !sorting.asc})}
                  >
                    {t('head.spend')}
                  </TableSortLabel>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody sx={{position: 'relative'}}>

              {campaigns.data.map(row => (
                <CampaignListRow
                  campaign={row}
                  updateCampaignList={updateCampaignList}
                  setOpenReplicateModal={(id) => setOpenReplicateModal(id)}
                  key={row.id}
                  noDataToDisplay={noDataToDisplay && !campaigns.isLoading}
                  isLoading={campaigns.isLoading}
                  loadingHandler={(state) => setCampaigns({...campaigns, isLoading: state})}
                  report={row.report_data}
                  currency={CURRENCYICONS[currentUser?.currency]}
                  archiveCampaign={archiveCampaign}
                  // replicateCampaign={replicateCampaign}
                />
              ))}

              {noDataToDisplay ? (
                <NoCampaigns sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '500px',
                backgroundColor:'#FFFFFF',
                zIndex: 5}} />
              ) : null}

            </TableBody>
          </Table>

        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          count={campaigns.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{
            disabled: campaigns.isLoading || page === Math.floor(campaigns.totalCount / rowsPerPage),
          }}
          backIconButtonProps={{
            disabled: campaigns.isLoading || page === 0,
          }}
          component='div'
        />
      </Paper>
      <Modal open={!!openReplicateModal} onClose={() => setOpenReplicateModal(null)}>
        <div>
          <ReplicateCampaignModal
            campaignId={openReplicateModal}
            onClose={() => setOpenReplicateModal(null)}
            onReplicate={(campaignId, userId) => replicateCampaign(campaignId, userId)} />
        </div>
      </Modal>
    </AuthTitleWrapper>
  );
}

export default CampaignList;