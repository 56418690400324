import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { AppCategory, CampaignFormDTO, CampaignObjective, CategoryApp } from "../../types";
import {
  Box, Button,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { style } from "./style";
import "../AppsSitesSelectBox/style.scss";
import { AddOutlinedIcon, InfoIcon, IntCheckbox, IntFieldController, IntSelect } from "../../../global";
import IntAutocomplete from "../../../global/components/IntForm/IntAutocomplete";
import AppsSitesSelectBox from "../AppsSitesSelectBox";
import { filtersConfig } from "../../utils/config";
import '../../styles/style.scss'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ENVIRONMENT } from "../../utils";
import AppsSitesCategoriesSelectBox from "../AppsSitesCategoriesSelectBox";
import DictService from "../../../dict/services/dict.service";
import WhiteListFiles from "../WhiteListFiles";
import { NotificationService } from "../../../global/services";
import CampaignService from "../../services/campaign.service";
import IntSwitch from "../../../global/components/IntForm/IntSwitch";
import ConfirmModal from "../../../global/modals/confirmModal";

type Props = {
  footer: ReactNode;
}

enum dTHelper {
  'all' = 1,
  'ios'= 3,
  'android' = 4
}

const maxFileSize: number = 10485760; // 10MB in bytes

function PlacementForm({ footer }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.placement',
  });
  const [whiteLists, setWhiteLists] = useState<any[]>([]);
  const [appCategories, setAppCategories] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [nameForDelete, setNameForDelete] = useState<Nullable<string>>(null);
  const [deviceTypes, setDeviceTypes] = useState<Array<{ value: number; label: string }>>([]);
  const [languages, setLanguages] = useState<Array<{ value: string; label: string }>>([]);

  function getDeviceTypes() {
    DictService.getDictionary('device_types')
      .then((deviceTypes: any) => {
        setDeviceTypes(deviceTypes.map(({ key }) => ({
          value: key,
          label: t(`deviceTypes.${key}`),
        })));
      });
    DictService.getDictionary('languages')
      .then((lang: any) => {
        setLanguages(lang.map(({ value, title }) => ({
          value,
          label: title,
        })));
      });
  }

  const {
    control,
    setValue,
  } = useFormContext<CampaignFormDTO>();

  const [excludeAllGames, goal, platform, has_whitelist, campaignId] = useWatch({
    control,
    name: ['placement.excludeAllGames', 'bidding_strategy.goal', 'bidding_strategy.app_promotion.app_platform_type', 'has_whitelist', 'campaignId'],
  });

  const getUploadedWhiteLists = () => {
    CampaignService.getWhitelists(campaignId as string).then(response => {
      if(response.data.type.code === 0) {
        if(!response?.data?.data.length) {
          setValue("has_whitelist", false);
        }
        setWhiteLists(response.data.data);
      }
    })
  }

  function handleCSVUpload(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      if(file.size < maxFileSize) {
        if(!campaignId){
          return
        }
        CampaignService.uploadWhitelist(file, campaignId).then(res => {
          if(res.data.type.code === 0){
            if(!whiteLists.length){
              setValue("has_whitelist", true);
            }
            getUploadedWhiteLists()
          }
        })
      } else {
        NotificationService.handleError(t('fileSizeError'));
      }
    }
  }

  const confirmationCancel = () => {
    setNameForDelete(null);
    setConfirmOpen(false);
  }

  const confirm = () => {
    if(!campaignId || !nameForDelete){
      return
    }
    CampaignService.deleteWhitelistByName(nameForDelete, campaignId).then(res => {
      if(res.data.type.code === 0){
        setConfirmOpen(false)
        getUploadedWhiteLists()
      }
    })
  }

  function removeWhitelist(name: string) {
    setNameForDelete(name);
    setConfirmOpen(true);
  }

  function downloadWhitelist(name: string) {
    if(!campaignId){
      return
    }
    CampaignService.downloadWhitelistByName(name, campaignId).then(res => {
      if(res.data.type.code === 0){
        const filename = res.data.data.headers['Content-Disposition'][0].split('filename=')[1].replaceAll('"', '');
        const url = window.URL.createObjectURL(new Blob([atob(res.data.data.body)], {type: "data:application/octet-stream;base64"}))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    })
  }

  function getAppCategories() {
    DictService.getAppGameCategories()
      .then((cat: any[]) => {
        setAppCategories((cat || []).map(function transferToOption(app: AppCategory) {
          return {
            label: app.category_name,
            value: app.category_id,
          };
        }));
      });
  }

  function getGameCategories() {
    DictService.getAppGameCategories(CategoryApp.GAME)
      .then((games: any[]) => {
        setGameCategories((games || []).map(function transferToOption(app: AppCategory) {
          return {
            label: app.category_name,
            value: app.category_id,
          }
        }))
      });
  }

  useEffect(() => {
    getAppCategories();
    getDeviceTypes();

    if(goal && goal === CampaignObjective.APP_INSTALLS) {
      // setValue('placement.environment', 'app');
      if(platform ) {
        setValue('placement.deviceType', dTHelper[platform]);
      }
    } else {
      // setValue('placement.environment', 'all');
      setValue('placement.deviceType', dTHelper['all']);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if(appCategories.length) {
      filtersConfig[0].dataList = appCategories;
    }
  }, [appCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!excludeAllGames && !gameCategories.length) {
      getGameCategories();
    }
  }, [excludeAllGames, gameCategories.length]);

  useEffect(() => {
    if(campaignId) {
      getUploadedWhiteLists();
    }
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={style.placement}>
      <Typography sx={style.title}>
        {t('title')}
      </Typography>
      <Box>
        <Divider />
        <Box sx={style.placementInputs}>
          <Box sx={style.appCategories}>
            <Box sx={style.labelWithTooltip}>
              <Typography sx={style.labelWithTooltip.fieldLabel}>{t('appCategories')}</Typography>
              <Tooltip title={t('appCategoriesTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <AppsSitesCategoriesSelectBox data={appCategories} control={control} setValue={setValue}/>
          </Box>

          <Box sx={[style.field, style.devicesToDisplay, {position: 'relative'}]}>
            <Box sx={style.labelWithTooltip}>
              <Typography sx={style.labelWithTooltip.fieldLabel}>{t('SearchForSpecificAppsSites')}</Typography>
              <Tooltip title={t('SearchForSpecificAppsSitesTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <AppsSitesSelectBox filtersConfig={filtersConfig} control={control} setValue={setValue} />
          </Box>
          <Divider sx={{width: '100%'}}/>
          {goal === CampaignObjective.APP_INSTALLS ? (
            <>
            <Box sx={{display: 'flex', width: '100%', gap: '16px'}}>
              <Box sx={[style.field, style.devicesToDisplay]}>
                <Typography sx={style.fieldLabel}>{t('environment')}</Typography>
                <IntFieldController
                  className={'border-color-E6E9ED radius-8 int-select'}
                  control={control}
                  name='placement.environment'
                  Component={IntSelect}
                  IconComponent={ExpandMoreIcon}
                  // disabled={isAppCampaign}
                  sx={[style.field, style.selectField]}
                  options={ENVIRONMENT}
                />
              </Box>
              <Box sx={[style.field, style.devicesToDisplay]}>
                <Typography sx={style.fieldLabel}>{t('devicesToDisplay')}</Typography>
                <IntFieldController
                  className={'border-color-E6E9ED radius-8 int-select'}
                  control={control}
                  name='placement.deviceType'
                  Component={IntSelect}
                  IconComponent={ExpandMoreIcon}
                  // disabled={isAppCampaign}
                  sx={[style.field, style.selectField]}
                  options={deviceTypes}
                />
              </Box>
              <Box sx={style.inputField}>
                <Typography sx={style.fieldLabel}>{t('minOsVersion')}</Typography>
                <IntFieldController control={control} name='placement.min_os_version' sx={style.input} />
              </Box>
            </Box>
            <Box sx={style.placementSelectFields}>
              <Box sx={[style.field, style.languageField]}>
                <Typography sx={style.fieldLabel}>{t('languages')}</Typography>
                <IntFieldController
                  className={'border-color-E6E9ED radius-8 int-select'}
                  control={control}
                  name='placement.languages'
                  Component={IntAutocomplete}
                  popupIcon={<ExpandMoreIcon />}
                  sx={[style.field, style.selectField]}
                  options={languages}
                  placeholder={t<string>('selectLanguage')}
                  multiple
                  extractValue
                />
              </Box>
            </Box>
          </>) : (
            <>
              <Box sx={style.placementSelectFields}>
                <Box sx={[style.field, style.devicesToDisplay]}>
                  <Typography sx={style.fieldLabel}>{t('environment')}</Typography>
                  <IntFieldController
                    className={'border-color-E6E9ED radius-8 int-select'}
                    control={control}
                    name='placement.environment'
                    Component={IntSelect}
                    IconComponent={ExpandMoreIcon}
                    // disabled={isAppCampaign}
                    sx={[style.field, style.selectField]}
                    options={ENVIRONMENT}
                  />
                </Box>
                <Box sx={[style.field, style.languageField]}>
                  <Typography sx={style.fieldLabel}>{t('languages')}</Typography>
                  <IntFieldController
                    className={'border-color-E6E9ED radius-8 int-select'}
                    control={control}
                    name='placement.languages'
                    Component={IntAutocomplete}
                    popupIcon={<ExpandMoreIcon />}
                    sx={[style.field, style.selectField]}
                    options={languages}
                    placeholder={t<string>('selectLanguage')}
                    multiple
                    extractValue
                  />
                </Box>
              </Box>
              <Box sx={style.placementSelectFields}>
                <Box sx={[style.field, style.devicesToDisplay]}>
                  <Typography sx={style.fieldLabel}>{t('devicesToDisplay')}</Typography>
                  <IntFieldController
                    className={'border-color-E6E9ED radius-8 int-select'}
                    control={control}
                    name='placement.deviceType'
                    Component={IntSelect}
                    IconComponent={ExpandMoreIcon}
                    // disabled={isAppCampaign}
                    sx={[style.field, style.selectField]}
                    options={deviceTypes}
                  />
                </Box>
              </Box></>
            )}

          {campaignId ? (<Box sx={{...style.pictures, marginTop: '24px'}}>
            <Typography sx={{color:'#505d68',}}>{t('uploadWhitelist')}</Typography>
            <Typography sx={style.fieldHelperText}>{t('whitelistFilesDescription')}</Typography>
            <WhiteListFiles
              files={whiteLists}
              onRemove={(id: string) => removeWhitelist(id)}
              onDownload={(id: string) => downloadWhitelist(id)}
            />
            {whiteLists.length < 5 ? (<Button
              startIcon={<AddOutlinedIcon color={'primary'} />}
              sx={[style.fileUpload]}
              component="label"
              disabled={false}
            >
              {t('uploadCsv')}
              <input
                type='file'
                hidden
                accept={".csv, text/csv"}
                style={style.uploadInput}
                onChange={(e) => handleCSVUpload(e)}
              />
            </Button>) : null}
          </Box>) : null}
          {!!whiteLists.length ? (<Box sx={style.whiteListToggleWrap}>
            <Typography sx={style.whiteListToggle}>
              {t('whitelistFiles')}
            </Typography>
            <Tooltip title={has_whitelist ? t('disable') : t('enable') }>
              <Box>
                <IntFieldController
                  control={control}
                  name='has_whitelist'
                  Component={IntSwitch}
                />
              </Box>
            </Tooltip>
          </Box>) : null}


          <Divider sx={{width: '100%'}}/>

          <Box sx={style.excludeAllGames}>
            <IntFieldController
              control={control}
              name='placement.exclude_non_gambling_publishers'
              Component={IntCheckbox}
            />
            <Typography sx={style.excludeAllGamesLabel}>{t('excludeNonGamblingPublishers')}</Typography>
          </Box>
        </Box>
      </Box>
      {footer}
      {confirmOpen ? (
        <ConfirmModal
          title={'Confirmation'}
          className={'whiteList-delete'}
          description={`You want to delete file  "${nameForDelete}"  permanently.`}
          subDescription={`Are You Sure ?`}
          leftBtnText={`Cansel`}
          rightBtnText={`Delete`}
          onCancel={ () => confirmationCancel()}
          onConfirm={ () => confirm()}
        />
      ) : null}
    </Box>
  )
}

PlacementForm.displayName = 'PlacementForm';

export default PlacementForm;