import { useEffect } from "react";
import { queryBuilderConfig } from "./config";
import _ from "lodash";

let skipRuleGet: boolean = false;

const QueryBuilder = ({data, saveRules}:{data: IBidding, saveRules(rules: any): void}) => {


  useEffect(() => {
    const element = window.$(`#builder_${data.id}`);

    element.queryBuilder(queryBuilderConfig);

    element.on('beforeAddRule.queryBuilder beforeAddGroup.queryBuilder', () => {
      skipRuleGet = true;
    })

    element.on('afterUpdateRuleValue.queryBuilder ' +
      'afterUpdateRuleOperator.queryBuilder ' +
      'afterDeleteRule.queryBuilder ' +
      'afterDeleteGroup.queryBuilder ' +
      'afterUpdateGroupCondition.queryBuilder', () => {
      const _data = _.cloneDeep(data);
      !skipRuleGet && saveRules({..._data, rules: element.queryBuilder('getRules')})
      skipRuleGet = false;
    })

    if (data.rules && Object.keys(data.rules).length){
      element.queryBuilder('setRules', data.rules);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div id={`builder_${data.id}`}></div>
    </>
  )
}

// QueryBuilder.displayName = 'QueryBuilder';

export default QueryBuilder;