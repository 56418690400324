import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import clsx from "clsx";
import { ArrowIcon } from "../../../index";
import { adminPaths } from "../../../../config/routes/paths";

interface IProps {
  target?: string;
  moderationCount?: number;
  withToggle?: boolean;
  setSideBar?: any;
}

export default function AdminSidebar({
  target,
  moderationCount,

}: IProps) {
  const [isActive] = React.useState(false);

  return (
    <div className={clsx("admin-drawer-links", { active: isActive })}>
      <h3 className="admin-drawer-links-title">
        <span>{"Admin Links"}</span>
      </h3>
      <ul className="admin-drawer-links-list">
        <li>
          <Link to={adminPaths.customization} target={target}>
            <span>{"Customization"}</span>
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={adminPaths.accounts} target={target}>
            <span>{"Accounts"}</span>
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={adminPaths.invoiceUser} target={target}>
            <span>{"Payments"}</span>
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={adminPaths.campaigns} target={target}>
            <span>{"Campaigns"}</span>
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={adminPaths.moderation} target={target}>
            <div className="admin-sidebar-moderation-link">
              <span>{"Moderation"}</span>
              {moderationCount !== 0 && moderationCount && (
                <div className="admin-sidebar-moderation-link-count">
                  {moderationCount}
                </div>
              )}
            </div>
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
      </ul>
    </div>
  );
}
