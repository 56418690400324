export const queryBuilderConfig = {
  operators: [
    { type: "is", nb_inputs: 1, multiple: false, apply_to: ['string', 'number'] },
    { type: "equal", nb_inputs: 1, multiple: false, apply_to: ['string', 'number'] },
    { type: "is_not", nb_inputs: 1, multiple: false, apply_to: ['string', 'number'] },
    { type: "in_ip_range", nb_inputs: 1, multiple: false, apply_to: ['string', 'number'] },
    { type: "not_in_ip_range", nb_inputs: 1, multiple: false, apply_to: ['string', 'number'] },
    { type: "is_more_than", nb_inputs: 1, multiple: false, apply_to: ['integer'] },
    { type: "is_less_than", nb_inputs: 1, multiple: false, apply_to: ['integer'] },
  ],
  filters: [
    {
      id: 'app',
      label: 'App',
      type: 'string',
      placeholder: 'picsart.studio',
      operators: ['is', "is_not"],
      input: 'text'
    },{
      id: 'site',
      label: 'Site',
      type: 'string',
      placeholder: 'google.com',
      operators: ['is', "is_not"],
      input: 'text'
    },{
      id: 'creative_format',
      label: 'Creative format',
      type: 'string',
      input: 'select',
      operators: ['is', "is_not"],
      values: ['banner', 'native', 'video']
    },{
      id: 'creative_size',
      label: 'Creative size',
      type: 'string',
      input: 'select',
      operators: ['is', "is_not"],
      values: [
        "970x90",
        "728x90",
        "320x50",
        "300x250",
        "300x100",
        "250x250",
        "300x600",
        "160x600",
        "240x400"
      ]
    },{
      id: 'position',
      label: 'Position',
      type: 'integer',
      input: 'select',
      operators: ['is', "is_not"],
      values: [
        {value: 0, label: 'Unknown'},
        {value: 1, label: 'Above the Fold'},
        {value: 2, label: 'DEPRECATED'},
        {value: 3, label: 'Below the Fold'},
        {value: 4, label: 'Header'},
        {value: 5, label: 'Footer'},
        {value: 6, label: 'Sidebar'},
        {value: 7, label: 'Full Screen'}
      ]
    },{
      id: 'interstitial',
      label: 'Interstitial',
      type: 'boolean',
      input: 'radio',
      operators: ['is'],
      values: [{true: 'Yes' }, {false: 'No'}]
    },{
      id: 'rewarded',
      label: 'Rewarded',
      type: 'boolean',
      input: 'radio',
      operators: ['is'],
      values: [{true: 'Yes' }, {false: 'No'}]
    },{
      id: 'environment',
      label: 'Environment',
      type: 'string',
      input: 'select',
      operators: ['is', "is_not"],
      values: ["Web", "App"]
    },{
      id: 'os',
      label: 'OS',
      type: 'string',
      input: 'select',
      operators: ['is', "is_not"],
      values: ["Android", "iOS", "Windows", "macOS"]
    },{
      id: 'content_language',
      label: 'Content language',
      type: 'string',
      operators: ['is'],
      input: 'text'
    },{
      id: 'device_make',
      label: 'Device make',
      type: 'string',
      operators: ['is'],
      input: 'text'
    },{
      id: 'device_model',
      label: 'Device model',
      type: 'string',
      operators: ['is'],
      input: 'text'
    },{
      id: 'device_connection',
      label: 'Device connection',
      type: 'integer',
      input: 'select',
      operators: ['is', "is_not"],
      values: [
        {value: 0, label: 'Unknown'},
        {value: 1, label: 'Ethernet'},
        {value: 2, label: 'WIFI'},
        {value: 3, label: 'Cellular Network Unknown Generation'},
        {value: 4, label: 'Cellular Network 2G'},
        {value: 5, label: 'Cellular Network 3G'},
        {value: 6, label: 'Cellular Network 4G'},
      ]
    },{
      id: 'device_ip',
      label: 'Device IP',
      type: 'string',
      operators: ['in_ip_range','not_in_ip_range'],
      input: 'text'
    },{
      id: 'ad_exchange',
      label: 'Ad Exchange',
      type: 'string',
      input: 'select',
      operators: ['is', "is_not"],
      values: ["Google", "Verve", "Taboola", "Yandex", "3rdpresence"]
    },{
      id: 'display_manager',
      label: 'Display manager',
      type: 'string',
      input: 'select',
      operators: ['is', "is_not"],
      values: [
        "GOOGLE",
        "GoogleMobileAds-Android",
        "GoogleMobileAds-iOS",
        "InteractiveMediaAds-Android",
        "PUBLISHER",
        "taboola",
        "APS",
        "AdView",
        "AdvertySDK",
        "BidMachine SDK",
        "GFP SSP RTB",
        "GadsmeSDK",
        "Google IMA SDK",
        "GoogleMobileAds-Android",
        "GoogleMobileAds-iOS",
        "HyBid",
        "MTG",
        "Orbis",
        "PubMatic_OpenWrap_SDK",
        "RixEngine",
        "Smaato",
        "Smart RTB+",
        "TradPlus",
        "adinmo",
        "adjoe/video",
        "algorix",
        "bigo.sg",
        "inmobi",
        "opera",
        "taboola",
        "topon"
      ]
    },{
      id: 'visits_1d',
      label: 'Visits 1d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many visits in last 1 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'visits_7d',
      label: 'Visits 7d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many visits in last 7 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'visits_30d',
      label: 'Visits 30d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many visits in last 30 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'visits_total',
      label: 'Visits total',
      type: 'integer',
      input: 'text',
      placeholder: 'Total number of visits',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'impressions_1d',
      label: 'Impressions 1d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many impressions in last 1 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'impressions_7d',
      label: 'Impressions 7d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many impressions in last 7 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'impressions_30d',
      label: 'Impressions 30d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many impressions in last 30 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'impressions_total',
      label: 'Impressions total',
      type: 'integer',
      input: 'text',
      placeholder: 'Total number of impressions',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'clicks_1d',
      label: 'Clicks 1d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many clicks in last 1 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'clicks_7d',
      label: 'Clicks 7d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many clicks in last 7 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'clicks_30d',
      label: 'Clicks 30d',
      type: 'integer',
      input: 'text',
      placeholder: 'How many clicks in last 30 day',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'clicks_total',
      label: 'Clicks total',
      type: 'integer',
      input: 'text',
      placeholder: 'Total number of clicks',
      operators: ["is", "is_more_than", "is_less_than"]
    },{
      id: 'bid_floor',
      label: 'Bid floor',
      type: 'double',
      input: 'number',
      placeholder: 'Bid floor of the bid',
      operators: ["is", "is_more_than", "is_less_than"]
    }]
}