
export const style = {

  fileBox: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover > svg': {
      display: 'unset !important',
    },
    '&:hover > img': {
      opacity: 0.1,
    }
  },
  fileName: {
    color: '#3A77EB',
    padding: '0 8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(58, 119, 235, 0.04)',
    },
  },
  deleteIcon: {
    opacity: 0.3,
    cursor: 'pointer',
    '&:hover' : {
      opacity: 0.5,
    }
  }
}