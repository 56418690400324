import { Theme } from "@mui/material";

export const style = {
  moderation: (theme: Theme) => ({
    backgroundColor: '#f4f6fa',
    width: '100%',
    padding: theme.spacing(5, 8, 7),
    borderRadius: 4,
    maxWidth: 680,
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center',
    marginBottom: '16px'
  }),
  paperApprove: {
    width: 500,
  },
  title: {
    color: '#292f41',
    lineHeight: 1.4,
    fontSize: 20,
    fontWeight: 600,
  },
  label: {
    fontSize: 14,
    letterSpacing: '0.8px',
    color: '#292f41',
    marginTop: 0.5,
    marginBottom: 1.5,
  },
  unit: {
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
  },
  component: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  components: {
    display: 'flex',
    gap: 3,
  },
  actions: {
    display: 'flex',
    gap: 2,
    height: '36px',
    alignSelf: 'flex-end',
  },
  action: {
    borderRadius: 5,
    width: 112,
  },
  reject: {
    borderColor: '#e14b4b',
    color: '#f33f8f',
    '&:hover': {
      borderColor: '#e14b4b',
    }
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    borderRadius: 3,
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    width: 400,
  },
  modalTitle: (theme: Theme) => ({
    textAlign: 'center',
    padding: theme.spacing(3, 0),
  }),
  content: {
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
  },
  button: {
    width: 150,
  },
  input: {
    width: '100%',
  },
  chooseExchange: {
    fontSize: 14,
    color: '#292f41',
    marginBottom: '24px'
  },
  adExchange: {
    backgroundColor: 'transparent',
    '&>div': {
      paddingLeft: '0'
    },
    '& fieldset': {
      border: 'none',
    },
    '& p': {
      fontSize: 14,
      color: '#292f41',
    },
  },
  exchange: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  divider: (theme: Theme) => ({
    margin: theme.spacing(3, 0),
  })
};