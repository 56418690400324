/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 10/25/2023
 * All Rights Reserved.
 */
import { style } from "./style";
import { Box, Button, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import { formatDate, IntCheckbox, IntFieldController, spin } from "../../../global";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { CampaignFormDTO, CampaignStatus } from "../../types";
import { useTranslation } from "react-i18next";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import IntAutocomplete from "../../../global/components/IntForm/IntAutocomplete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  authUserSelector,
  selectCurrentUserSpaceMaxMargin, setSpaceMaxMargin,
} from "../../../store/reducers/authentication";
import CampaignService from "../../services/campaign.service";
import { NotificationService } from "../../../global/services";
import DictService from "../../../dict/services/dict.service";
import { CURRENCYICONS } from "../../../store";
import { currentUserSelector } from "../../../store/reducers/user";
import { roles } from "../../../../_constants";
import AdminUserSearch from "../../../adminApp/modules/payment/components/AdminInvoiceList/AdminUserSearch";

const progressBar = {
  borderRadius: '50%',
  border: '3px solid transparent',
  borderTopColor: '#326afc6e',
  borderRightColor: '#326afc6e',
  animation: `${spin} 1s linear infinite`,
  width: 15,
  height: 15,
  position: 'absolute',
}

type Props = {
  footer: ReactNode;
}

const selectState = [1,1,1,1,1,1,1,1,1,1,1,1,];
const unselectState = [0,0,0,0,0,0,0,0,0,0,0,0];

let AD_EXCHANGES: ISelectOption[] = [];

function OwnerForm({ footer }: Props) {
  const dispatch = useDispatch();
  const adminUserSearchRef = useRef(null);

  const authUser = useSelector(authUserSelector);
  const currentUser = useSelector(currentUserSelector);
  const maxMargin = useSelector(selectCurrentUserSpaceMaxMargin);

  const [categories, setCategories] = useState<ISelectOption[]>([]);
  const [maxMarginLoad, setMaxMarginLoad] = useState<boolean>(false);
  const isWLOwner = authUser.roles.includes(roles.WL_OWNER); // eslint-disable-line

  const { control, setValue } = useFormContext<CampaignFormDTO>();

  const [intentTagDate, intentVerified, statusKey, test_devices, slots, account_manager_id] = useWatch({
    control,
    name: ['intentTagDate', 'intentVerified', 'status_key', 'test_devices', 'slots', 'account_manager_id'],
  });

  const { t } = useTranslation('translation', { keyPrefix: 'campaign.owner' });

  const biddingStrategy = useWatch({
    name: 'bidding_strategy',
  });

  const addSecondaryEvent = () => {
    const secondaryList = test_devices ? [...test_devices] : [];
    secondaryList?.push("")
    setValue('test_devices', secondaryList)
  }
  const removeSecondaryEvent = (index: number) => {
    const secondaryList = [...test_devices];
    secondaryList.splice(index, 1);
    setValue('test_devices', secondaryList)
  }

  const setSecondaryEventName = (name: string, index: number) => {
    const secondaryList = [...test_devices];
    secondaryList[index] = name;
    setValue('test_devices', secondaryList)
  }

  const getMaxMargin = () => {
    setMaxMarginLoad(true);
    CampaignService.getMaxMarginByUserId(currentUser.id).then(res => {
      if(res?.data?.type?.code === 0) {
        dispatch(setSpaceMaxMargin(res?.data?.data))
        setMaxMarginLoad(false);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    });
  }

  const changeSlotState = (index, state) => {
    const _slots = [...slots];
    _slots[index] = state;
    setValue('slots', _slots);
  }

  // const onInit = () => {
  //   if(account_manager_id) {
  //     getUserByIdRequest(account_manager_id).then(res => {
  //       if(res?.data?.type?.code === StatusCode.OK) {
  //         const user = res?.data?.data;
  //         const name = `${user?.first_name} ${user?.last_name}`;
  //         if(adminUserSearchRef && adminUserSearchRef.current) {
  //           adminUserSearchRef.current.outsideAccountSet({
  //             space_name: name,
  //             currency: user?.currency,
  //             space_id: user?.id
  //           });
  //         }
  //       } else {
  //         NotificationService.handleError(res?.data?.type?.message);
  //       }
  //     })
  //   }
  // }

  useEffect(() => {
    DictService.getDictionary('iab_categories')
      .then((categories: any) => {
        setCategories(categories.map(({ value, title }) => ({
          label: title,
          value: value,
        })));
      });

    AD_EXCHANGES = [
      { label: t('google'), value: 'google' },
      { label: t('verve'), value: 'verve' },
      { label: t('taboola'), value: 'taboola' },
      { label: t('yandex'), value: 'yandex' },
      { label: t('3rdpresence'), value: '3rdpresence' }
    ];

    // onInit();

    return () => {
     setCategories([]);
     dispatch(setSpaceMaxMargin(null))
    }
    // eslint-disable-next-line
  }, []);

  return isWLOwner ? (
    <Box sx={style.owner}>
      <Typography sx={style.title}>{t('title')}</Typography>
      <Divider />

      <Box sx={style.content}>
        <Box sx={style.accountManagerField}>
          <Typography sx={style.label}>{t('accountManager')}</Typography>
          <AdminUserSearch ref={adminUserSearchRef} initId={account_manager_id} onUserSelect={(account) => setValue('account_manager_id', account?.space_id)}/>
        </Box>
        {statusKey !== CampaignStatus.IN_MODERATION ? (
          <Box sx={style.rowFields}>
            <Box sx={style.field}>
              <Typography sx={style.label}>
                {t('websitesAndCategories')}
              </Typography>
              <IntFieldController
                control={control}
                name='campaign_category_keys'
                Component={IntAutocomplete}
                options={categories}
                extractValue
                multiple
                sx={style.input}
              />
            </Box>
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('chooseExchange')}</Typography>
              <IntFieldController
                control={control}
                name='exchange_id'
                Component={IntAutocomplete}
                options={AD_EXCHANGES}
                extractValue
                multiple
                sx={style.input}
              />
            </Box>
          </Box>
        ) : null}


        <Box sx={style.field}>
          <Typography sx={style.label}>{t('impressionURL')}</Typography>
          <IntFieldController
            control={control}
            name='impressionURL'
            sx={style.input}
            multiline
            rows={3}
          />
        </Box>
        <Box sx={[style.fields, style.wrapFields]}>
          {biddingStrategy?.goal && (
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('goal')}</Typography>
              <Box sx={style.inputLike}>
                <Typography sx={style.inputText}>{t(`goals.${biddingStrategy.type}`)}</Typography>
              </Box>
            </Box>
          )}
          {biddingStrategy?.type && (
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('deliveryStrategy')}</Typography>
              <Box sx={style.inputLike}>
                <Typography sx={style.inputText}>{t(`types.${biddingStrategy.goal}`)}</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={style.whitelabelFields}>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('dealLink')}</Typography>
            <IntFieldController control={control} name='deal_link' sx={style.input} />
          </Box>
          <Box sx={style.rowFields}>
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('cpx')}</Typography>
              <IntFieldController
                control={control}
                name='cpx'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {CURRENCYICONS[currentUser?.currency]}
                    </InputAdornment>
                  )
                }}
                sx={style.cpm}
              />
            </Box>
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('t')}</Typography>
              <IntFieldController control={control} name='t' sx={style.input} />
            </Box>
          </Box>
          <Box sx={style.rowFields}>
            <Box sx={style.marginField} onClick={() => !maxMargin && getMaxMargin()}>
              <Typography sx={style.label}>{t('margin')}</Typography>
              <IntFieldController
                control={control}
                name='margin'
                sx={style.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>%</InputAdornment>
                  ),
                  startAdornment: maxMarginLoad ? (
                    <InputAdornment position='end'>
                      <Box component={'span'} sx={progressBar} />
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Box>
          </Box>

          {/*<Box sx={style.field}>*/}
          {/*  <Typography sx={style.label}>{t('bidMultipliers')}</Typography>*/}
          {/*  <IntFieldController*/}
          {/*    control={control}*/}
          {/*    name='traffic_targeting'*/}
          {/*    sx={style.input}*/}
          {/*    multiline*/}
          {/*    rows={4}*/}
          {/*  />*/}
          {/*</Box>*/}

          <Box sx={style.deviceField}>
            <Typography sx={style.secondaryTitle}>{'Test Devices'}</Typography>
            {
              test_devices?.map((item: string, index: number) => (
                <TextField
                  sx={style.secondaryEventName}
                  key={index}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position='end'>
                          <DeleteOutlineIcon
                            viewBox='0 0 36 36'
                            sx={style.deleteIconSecondary}
                            onClick={() => removeSecondaryEvent(index)}
                          />
                        </InputAdornment>
                      </>
                    )
                  }}
                  value={item}
                  onChange={(e) => setSecondaryEventName(e.target.value, index)}
                />
              ))
            }
            <Button disabled={test_devices && test_devices.length > 9} sx={style.addSecondary} variant={'outlined'} onClick={addSecondaryEvent}>Add</Button>
          </Box>

          <Divider />

          <Box sx={style.block}>
            <Typography sx={style.blockTitle}>{'Models'}</Typography>
            <Box>
              <Box sx={style.blockField}>
                <Typography sx={style.label}>{t('telcoModel')}</Typography>
                <IntFieldController control={control} name='telcoModel' sx={style.input} />
              </Box>
              <Box sx={style.blockField}>
                <Typography sx={style.label}>{t('noTelcoModel')}</Typography>
                <IntFieldController control={control} name='noTelcoModel' sx={style.input} />
              </Box>
              <Box sx={style.blockField}>
                <Typography sx={style.label}>{t('dummyModels')}</Typography>
                <IntFieldController control={control} name='dummy_models' sx={style.input} />
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box sx={style.block}>
            <Typography sx={style.blockTitle}>{'Slots'}</Typography>
            <Box>
              <Box sx={[style.field, style.verified]}>
                <Typography sx={style.label}>{'Include Unknown Users'}</Typography>
                <IntFieldController
                  control={control}
                  name='slots_include_unknown'
                  Component={IntCheckbox}
                />
              </Box>
              <Box sx={{display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                border: '1px solid #dfe7f0',
                marginTop: '22px',
                borderRight: 'none',
                borderRadius: '4px',
              }}>
                {slots.map((slot, index) => (
                  <Box key={index} sx={{
                    display: 'flex',
                    padding: '8px',
                    width: 'calc(100% / 12)',
                    minWidth: '40px',
                    flexDirection: 'column',
                    borderRight: '1px solid #dfe7f0',
                    justifyContent: 'space-between'}}>
                    <Box
                      onClick={() => changeSlotState(index, slot === 1 ? 0 : 1)}
                      sx={{
                        width: '24px',
                       height: '24px',
                       border: '1px solid #dfe7f0',
                       cursor: 'pointer',
                       margin: '0 auto 8px auto',
                       borderRadius: '4px',
                       backgroundColor: slot === 1 ? '#3a77eb' : '#f5f7f8'}}>
                    </Box>
                    <Box
                      key={index}
                      sx={{
                        height: '28px',
                        borderTop: '1px solid #dfe7f0',
                        paddingTop: '5px',
                        margin: '0 -8px 0 -8px',
                        color: '#292f41',
                       textAlign: 'center',
                       }}>{index + 1}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Button sx={style.slotBtn} variant={'outlined'} onClick={() => setValue('slots', selectState)}>{'Select all'}</Button>
              <Button sx={style.slotBtn} variant={'outlined'} onClick={() => setValue('slots', unselectState)}>{'Unselect all'}</Button>
            </Box>
          </Box>

          <Divider />

          <Box sx={[style.field, style.verified]}>
            <Typography sx={style.label}>{t('intentVerified')}</Typography>
            <IntFieldController
              control={control}
              name='intentVerified'
              Component={IntCheckbox}
            />
          </Box>
          <Box sx={[style.field, style.verified]}>
            <Typography sx={style.label}>{t('disregardTargeting')}</Typography>
            <IntFieldController
              control={control}
              name='disregard_targeting'
              Component={IntCheckbox}
            />
          </Box>
          {(intentTagDate && intentVerified) && (
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('verificationDate')}: {formatDate(intentTagDate, 'MM/dd/yyyy')}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      {footer}
    </Box>
  ): null
}

OwnerForm.displayName = 'OwnerForm';

export default OwnerForm;
