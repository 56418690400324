import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ISpace, IUser, IWhitelabelSettings } from "../../../../types";
import { roles } from "../../../../_constants";
import "./style.scss";
import clsx from "clsx";
import { Box, Button } from "@mui/material";
import { balanceParser, getSpaceImage, Hamburger } from "../../../index";
import { authUserSelector } from "../../../store/reducers/authentication";
import { whitelabelSettingsSelector } from "../../../store/reducers/admin";
import { logout} from "../../../../utils/auth.utils";
import { spin } from "../../utils";
import { AdminDrawer } from "../../../adminApp/components/AdminDrawer";
import { Sidebar } from "../Sidebar";
import  {ReactComponent as HeaderBalanceIcon} from "../../../../assets/images/header_balance.svg"
import { currentUserSelector } from "../../../store/reducers/user";

const styles = {
  logoutBtn: {
    backgroundColor: '#84EBFD',
    boxShadow: 'none',
    borderRadius: 50,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#84EBFD',
      opacity: 0.7
    }
  },
  progressBar: {
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: '#326afc6e',
    borderRightColor: '#326afc6e',
    animation: `${spin} 1s linear infinite`,
    width: 15,
    height: 15,
    position: 'absolute',
    right: '24px',
    top: '6px'
  }
}

interface IProps {
  title: string | JSX.Element | any;
  iconName: string;
  className?: string;
  children: JSX.Element[] | JSX.Element | any;
  isProfilePage?: boolean;
  logout?: Function;
  currentBalance?: number;
  currentUserRole?: number;
  currentUserSpaceName?: string;
  currentUserSpaceImage?: string;
  header?: JSX.Element[] | JSX.Element;
  currentUser?: IUser;
  APIResponseSuccess?: Function;
  isOwnerAccount?: boolean;
  currentUserSpace?: ISpace;
  settings?: IWhitelabelSettings;
  modalType?: any;
  toggle?: any;
  isOpen?: any;
  closeModal?: any;
  modalTypes?: any;
  modalData?: any;
  openModal?: any;
}

const AuthTitleWrapper = (props:IProps) => {

  const {
    children,
    title,
    isProfilePage,
    className = "",
    header,
  } = props;

  const settings = useSelector(whitelabelSettingsSelector);
  const currentUser = useSelector(currentUserSelector);
  const authUser = useSelector(authUserSelector);

  const [adminPanelIsOpen, setAdminPanelIsOpen] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [navBarActiveClass, setNavBarActiveClass] = useState<string>("");

  const toggleMenu = () => {
    setActive(!active);
  };

  const openAdminPanel = () => {
    if (navBarActiveClass) {
      toggleMenu();
    }
    setAdminPanelIsOpen(!adminPanelIsOpen)
  };

  const handleKeyUp = (event: any) => {
    if (
      !adminPanelIsOpen &&
      event.keyCode === 191 &&
      event.target.tagName !== "INPUT"
    ) {
      openAdminPanel();
    }
  };

  useEffect(() => {
    if (authUser?.roles.includes(roles.USER_OWNER)) {
      document?.addEventListener("keyup", handleKeyUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (active) {
      document.body.style.position = "fixed";
      setNavBarActiveClass("is-active")
    } else {
      document.body.style.position = "static";
      setNavBarActiveClass("");
    }
  }, [active]);


  const isMyAccount = authUser?.id === currentUser?.id;

  return (
    <div className="dsp-auth-container">
      <div className="main-content">
        <div>
          <Sidebar onSidebarClick={() => adminPanelIsOpen && setAdminPanelIsOpen(false)} settings={settings} isActive={navBarActiveClass} />
          {adminPanelIsOpen && (
            <AdminDrawer
              open={adminPanelIsOpen}
              closeAdminPanel={() => {
                setAdminPanelIsOpen(false);
              }
            }
            />
          )}
          <div className="auth-title-wrapper-container">
            <div
              className={clsx("auth-title", {
                user: authUser?.roles.includes(roles.USER_OWNER),
              })}
            >
              {authUser?.roles.includes(roles.WL_OWNER) ? (
                  <div
                    className={clsx("auth-title-admin", {
                      open: adminPanelIsOpen,
                    })}
                    onClick={openAdminPanel}
                  >
                    <div className="auth-title-admin-account-image">
                      <img
                        src={getSpaceImage(currentUser?.profile_image?.picture_id)}
                        alt="space"
                      />
                    </div>
                    <div className="auth-title-admin-text">
                      <p className="auth-title-admin-account">
                        <span>{"Current Account"}</span>{" "}
                        {isMyAccount &&
                          `(My Account)`}
                      </p>
                      <p className="auth-title-admin-account-name">
                        {currentUser?.first_name + " " + currentUser?.last_name}{" "}
                      </p>
                    </div>
                    <div className="auth-title-admin-arrow">
                      <div
                        className={clsx("dropdown-arrow", {
                          open: adminPanelIsOpen,
                        })}
                      />
                    </div>
                  </div>
                ) : null
              }
            <div className="auth-title-meta">
                <span
                  className={clsx("auth-title-text", {
                    user: authUser?.roles.includes(roles.USER_OWNER),
                  })}
                >
                  {title}
                </span>
            </div>
            <div className="auth-title-billing">
              <div className={'balance-wrap'}>
                <div className={'balance'}>
                  <p className={'balance-label'}>Balance</p>
                  <p className={'balance-text'}>{currentUser.balanceLoad ? (
                    <Box component={'span'} sx={styles.progressBar} />) : balanceParser(currentUser.balance, currentUser.currency)}</p>
                </div>
                <div key={'balance'} className={'balance-icon'}>
                  <HeaderBalanceIcon />
                </div>
              </div>
              {isProfilePage ? (
                <div className="auth-title-logout">
                  <Button
                    className={'mai-button'}
                    onClick={() => logout()}
                    sx={styles.logoutBtn}
                    variant={'contained'}
                  >{'Logout'}</Button>
                </div>
                ) : null
              }
                <div className="menu-icon">
                  <Hamburger
                    sidebarToggle={toggleMenu}
                    sidebarOpen={!!navBarActiveClass}
                    icon="icon1"
                  />
                </div>
              </div>
            </div>
          </div>
          {header && <div className="auth-header">{header}</div>}
          <div className={`auth-children ${className}`}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default AuthTitleWrapper;
