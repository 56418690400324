const EMAIL_REGEX = /^[A-Z0-9._%+\-']+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;

const WEEKDAYS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export {
  EMAIL_REGEX,
  WEEKDAYS,
}