// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { tawkInit } from "../../../../utils";
import { IUser } from "../../../../types";
import { roles, TAWK_API } from "../../../../_constants";
import Loading from "../Loading";
import { isUserLoaded, setAuthUser, } from "../../../store/reducers/authentication";
import { setWhitelabel, whitelabelDataSelector } from "../../../store/reducers/admin";
import { hasCampaignSelector } from "../../../store/reducers/campaign";
import {
  getCompanyWhitelabelDataRequest,
  getCurrentSpaceBalanceRequest, getUserByIdRequest
} from "../../../../api";
import { i18n } from "../../../index";
import { InfoDialogContainer } from "../InfoDialogContainer";
import { StatusCode } from "../../types";
import { NotificationService } from "../../services";
import { setBalance, setBalanceLoad, setCurrentUser } from "../../../store/reducers/user";
import { logout } from "../../../../utils/auth.utils";
// TODO: Children type

const MainContainer = (props: any) => {
  const {children} = props;
  const fbContainer = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const userLoaded = useSelector(isUserLoaded)
  const whitelabelData = useSelector(whitelabelDataSelector)
  const hasCampaign = useSelector(hasCampaignSelector)

  const successCallback = async (user: IUser, authUserRoles: string[]) => {
    i18n.changeLanguage(user.locale.split('_')[0]);
    dispatch(setBalanceLoad(true));
    const balanceRes = await getCurrentSpaceBalanceRequest(user.id);
    dispatch(setBalance(balanceRes?.data?.data));
    dispatch(setBalanceLoad(false));

    if (authUserRoles.includes(roles.WL_OWNER)) {
      return null;
    }
    tawkInit(TAWK_API, () => {})
  };

  const getUserData = async () => {
    const currentUserId = sessionStorage.getItem('spaceID');
    const authUser = JSON.parse(localStorage.getItem('authState')).authUser;
    if(!authUser) {logout()}
    dispatch(setAuthUser(authUser));
    if(currentUserId && currentUserId !== authUser?.id) {
      const userRes = await getUserByIdRequest(currentUserId);
      if(userRes?.data?.type?.code === StatusCode.OK) {
        const user = userRes?.data?.data;
        dispatch(setCurrentUser(user));
        successCallback(user, authUser.roles);
      } else {
        NotificationService.handleError(userRes?.data?.type?.message);
      }
    } else {
      dispatch(setCurrentUser(authUser));
      sessionStorage.setItem('spaceID', authUser?.id);
      successCallback(authUser, authUser.roles);
    }
  }

  const getWhitelabel = async () => {
    const {data} = await getCompanyWhitelabelDataRequest();
    dispatch(setWhitelabel(data.data));
  }

  useEffect(() => {
    getWhitelabel();
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !userLoaded || (hasCampaign === null && userLoaded !== true) || !whitelabelData ? <Loading /> : (
    <div className="dsp">
      <div
        ref={fbContainer}
        className="fb-customerchat"
        attribution="install_email"
      />
      <Helmet>
        <title>{whitelabelData?.name}</title>
      </Helmet>
      <InfoDialogContainer>{children}</InfoDialogContainer>
    </div>
  );
}

export default MainContainer;
