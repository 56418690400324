import { Theme } from "@mui/material";

export const style = {
  owner: {
    width: '100%',
    maxWidth: 680,
  },
  title: (theme: Theme) => ({
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
    margin: theme.spacing(6, 0),
  }),
  content: (theme: Theme) => ({
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  fields: (theme: Theme) => ({
    display: 'flex',
    gap: theme.spacing(5),
  }),
  wrapFields: {
    flexWrap: 'wrap',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
  },
  accountManagerField: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    gap: 2,

    '& .admin-user-search-container': {
      width: '100%',

      '& li': {
        minWidth: '100%'
      }
    }
  },
  marginField: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    gap: 2,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
  },
  selectAll: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#3a77eb',
    cursor: 'pointer'
  },
  inputLike: {
    background: '#e9ecef',
    height: 40,
    padding: '8.5px 14px',
    borderRadius: 1,
  },
  inputText: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.38)',
    textTransform: 'capitalize',
  },
  whitelabelFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  rowFields: {
    display: 'flex',
    gap: 2,
  },
  input: {
    width: '100%',
    maxWidth: 680,
  },
  verified: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  halfRow: {
    width: '50%',
  },
  cpm: {
    '& input': {
      borderLeft: '1px solid #dfe7f0',
      borderRadius: 'unset !important',
      paddingLeft: 2,
    },
  },

  deviceField:{
    marginTop: '24px',
  },

  blockTitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
    marginBottom: 2,
  },

  block: {
    // border: '1px solid #0000001f',
    // padding: '8px'
    margin: '16px 0'
  },
  blockField: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
    marginBottom: '8px'
  },
  secondaryTitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
    marginLeft: 2,
    marginBottom: 2,
  },
  secondaryEventName: {
    width: '100%',
    marginBottom:'8px',
    backgroundColor: '#fff',
    '& input': {
      padding: '8px 50px 8px 14px'
    },
    '&:hover fieldset': {
      borderColor: '#658ffd !important'
    }
  },
  secondaryEventNameErr: {
    width: '100%',
    marginBottom:'8px',
    backgroundColor: '#fff',
    '& input': {
      padding: '8px 50px 8px 14px'
    },
    '& fieldset': {
      border: '2px solid red',
    },
    '& p': {
      color: 'red',
    },
    '&:hover fieldset': {
      borderColor: '#658ffd !important'
    }
  },
  addSecondary: {
    float: 'right',
    lineHeight: '12px',
    textTransform: 'none',
  },
  slotBtn: {
    float: 'right',
    lineHeight: '12px',
    marginLeft: '8px',
    marginTop: '8px',
    textTransform: 'none',
  },
  deleteIconSecondary: (theme: Theme) => ({
    width: 36,
    height: 36,
    cursor: 'pointer',
    position: 'absolute',
    color: '#658ffd',
    bottom: -5,
    right: 0,
  }),
}