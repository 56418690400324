import { ForgotPassword, Login, Signup } from "../../modules/auth/components";
import { adminPaths, appPaths, signInPaths } from "./paths";
import { ResetPassword } from "../../modules/auth/components/ResetPassword";
import { ConfirmRegistration } from "../../modules/auth/components/ConfirmRegistration";
import { NotFound } from "../../modules/global/components/NotFound";
import { lazy } from "react";


//=========================== app components ========================================

const Events = lazy(() => import('../../modules/events'));
const Billing = lazy(() => import('../../modules/Billing/Billing'));
const Report = lazy(() => import('../../modules/report/components/Report'));
const Profile = lazy(() => import('../../modules/profile/components/Profile'));
const Settings = lazy(() => import('../../modules/profile/components/Settings'));
const AudienceList = lazy(() => import('../../modules/audience/components/AudienceList'));
const CampaignList = lazy(() => import('../../modules/campaign/components/CampaignList'));
const CampaignForm = lazy(() => import('../../modules/campaign/components/CampaignForm'));
const EditAudience = lazy(() => import('../../modules/audience/components/EditAudience'));
const CreateAudience = lazy(() => import('../../modules/audience/components/CreateAudience'));
const AudienceDetails = lazy(() => import('../../modules/audience/components/AudienceDetails'));

//=========================== admin components ========================================

const Customization = lazy(() => import('../../modules/adminApp/modules/Customization'));
const Invoice = lazy(() => import('../../modules/adminApp/modules/payment'));
const InvoiceDetails = lazy(() => import('../../modules/adminApp/modules/payment/components/InvoiceDetails'));
const UserDetails = lazy(() => import('../../modules/adminApp/modules/payment/components/UserDetails'));
const AdminCampaigns = lazy(() => import('../../modules/adminApp/modules/adminCampaigns'));
const Moderation = lazy(() => import('../../modules/adminApp/modules/Moderation'));
const AdminAccounts = lazy(() => import('../../modules/adminApp/modules/account'));
const LegalEntityUser = lazy(() => import('../../modules/adminApp/modules/Moderation/components/LegalEntityUser/LegalEntityUser'));
const ModerationUser = lazy(() => import('../../modules/adminApp/modules/Moderation/components/ModerationUser/ModerationUser'));


export const appRoutesConfig = [
    {
      path: appPaths.reports,
      component: Report,
    },
    {
      path: appPaths.profile,
      component: Profile,
    },
    {
      path: appPaths.profileAdmin,
      component: Profile,
    },
    {
      path: appPaths.settings,
      component: Settings,
    },
    {
      path: appPaths.billing,
      component: Billing,
    },
    {
      path: appPaths.events,
      component: Events,
    },
    {
      path: appPaths.audience,
      component: AudienceList,
    },
    {
      path: appPaths.audienceCreate,
      component: CreateAudience,
    },
    {
      path: appPaths.audienceEdit,
      component: EditAudience,
    },
    {
      path: appPaths.audienceDetails,
      component: AudienceDetails,
    },
    {
      path: appPaths.campaigns,
      component: CampaignList,
    },
    {
      path: appPaths.editCampaign,
      component: CampaignForm,
    },
    {
      path: appPaths.createCampaign,
      component: CampaignForm,
    },
  ];


export const adminRoutesConfig = [
  {
    path: adminPaths.customization,
    component: Customization,
  },
  {
    path: adminPaths.invoiceUser,
    component: Invoice,
  },
  {
    path: adminPaths.invoiceDetails,
    component: InvoiceDetails,
  },
  {
    path: adminPaths.userDetails,
    component: UserDetails,
  },
  {
    path: adminPaths.campaigns,
    component: AdminCampaigns,
  },
  {
    path: adminPaths.moderation,
    component: Moderation,
  },
  {
    path: adminPaths.accounts,
    component: AdminAccounts,
  },
  {
    path: adminPaths.legalEntityUser,
    component: LegalEntityUser,
  },
  {
    path: adminPaths.moderationUser,
    component: ModerationUser,
  },
];

export const signInRoutesConfig = [
  {
    path: signInPaths.login,
    component: Login,
  },
  {
    path: signInPaths.confirmRegistration,
    component: ConfirmRegistration,
  },
  {
    path: signInPaths.signup,
    component: Signup,
  },
  {
    path: signInPaths.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: signInPaths.resetPassword,
    component: ResetPassword,
  },
]

export const notFoundConfig = NotFound;
export const defaultPath = appPaths.campaigns;